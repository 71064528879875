import React, {
  createRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Paper,
  Table,
  TableRow,
  MenuItem,
  Checkbox,
  TableHead,
  TableBody,
  TableCell,
  TextField,
  TablePagination,
  makeStyles,
  Tooltip,
  Zoom,
  TableSortLabel,
  TableContainer,
} from "@material-ui/core";
import { format } from "date-fns";
import { Badge, Button, Modal, Spinner } from "react-bootstrap";

import { getVariant } from "../utils/getVariant";
import { formatCurrency } from "../utils/formatCurrency";
import { formatToFloat } from "../utils/formatCurrency";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfOrder from "./PdfOrder/PdfOrder";
import { useHistory } from "react-router-dom";

import "../style.css";

import axios from "../services/Api";
import { BsVariant } from "../types/BsVariant";
import { sendBillEmailToCustomer } from "../utils/sendEmailToCustomer";
import { BillsToReceive } from "../types/BillsToReceive";
import ModalChangeBillSituation from "./ModalChangeBillSituation";
import useChangeBillSituation from "../hooks/changeBillSituation";
import {
  btnSuccess,
  btnWarning,
  colorSuccess,
  colorBlue,
  btnNfce,
} from "../utils/styles";
import { EmailsHistory } from "../types/EmailsHistory";
import { WhatsappHistory, whatsappStatus } from "../types/WhatsappHistory";
import { EmailToCustomer, ModalSendEmail } from "./ModalSendEmail";
import { formatDate } from "../utils/dateFormat";
import { verifyProductsJSON } from "../utils/verifyProductsJSON";
import { getTypeOrderIsEntry } from "../utils/getTypeOrderIsEntry";
import { getTypeOrderText } from "../utils/getTypeOrderText";
import StockService from "../services/StockService";
import { ModalThrowToBills } from "./ModalThrowToBills";
import useThrowToBills from "../hooks/throwToBills";
import { ThrowBillInstallment } from "../types/ThrowBillInstallment";
import { useSelector } from "react-redux";
import ApiResourceSelect from "./ApiResourceSelect";
import StockLocationService from "../services/StockLocationService";
import api from "../services/Api";
import BillToReceiveService from "../services/BillToReceiveService";
import BillToPayService from "../services/BillToPayService";
import { limitString } from "../utils/limitString";
import { DropdownButton } from "react-bootstrap";
import { Request } from "../types/Request";
import ModalError from "./ModalError";
import { ValidationBadge } from "./ValidationBadge";
import { BusinessCategory } from "../types/BusinessCategory";
import { useCompanyBranch } from "../hooks/companyBranch";

export type OrdersSituation = {
  statusSituation: string;
  commentsSituation: string;
  dateSituation: string;
};

export type ObjectOrder = {
  id: number;
  installments?: string;
  situation?: OrdersSituation[];
  sellerId?: string | number;
  sellerHasCommission?: boolean;
  sellerCommissionPercentage?: number;
};

export type HeadDataBaseProps = {
  reference: string;
  value: string;
  situation?: boolean;
  notSortable?: boolean;
};

export type BodyDataBaseProps = {
  id?: boolean;
  for: string;
  value: string;
  jsx?: JSX.Element;
  hidden?: boolean;
  secondaryRow?: boolean;
  limitText?: boolean;
};

export type Situation = {
  id: number;
  name: string;
  amount: number;
  remaining: number;
  status: string;
  dueDate: string;
  payment: string;
  customer: string;
  beneficiaryName?: string;
  bankAccount: string;
  recordType: string;
  accValue: number;
  taxValue: number;
  discountValue: number;
  feeValue: number;
  totalPaid: number;
  payedDate: string;
  partialPayments?: string | null;
  writeOffValue: number;
};

export type partialPayments = {
  payedDate: string;
  writeOffValue: number;
  feeValue: number;
  discountValue: number;
  taxValue: number;
  accValue: number;
  totalPaid: number;
  payment: string;
  bankAccount: number;
};

const SituationItens = [
  { name: "Em aberto", value: "open" },
  { name: "Em andamento", value: "progress" },
  { name: "Atendido", value: "attended" },
  { name: "Cancelado", value: "canceled" },
  { name: "Lançado em Pedidos", value: "launched" },
];

const OSSituationItens = [
  ...SituationItens,
  { name: "Aprovado", value: "aproved" },
];

type StockLocations = {
  id: number;
  name: string;
  description: string;
  balance?: string;
};

export type OnThrowToBillsData = {
  id: number;
  revenue: string;
  subCategoryId?: number;
  centerCost: string;
  installments: ThrowBillInstallment[];
  subCategoryIdCommission?: number;
  centerCostCommission?: string;
  installmentsCommission?: ThrowBillInstallment[];
};

export type CustomButton = {
  class?: string;
  content?: string | JSX.Element;
  title?: string;
  variant?: BsVariant;
  popup: string;
  onClick?: (id: string) => void;
  showCondition?: (id: string) => boolean;
};

export type SelectCheckbox = {
  reference?: string;
  onChange?: (checked: boolean, id: string) => void;
  checkedCondition?: (id: string) => boolean;
  showCondition?: (id: string) => boolean;
  onChangeSelectAll?: (checked: boolean) => void;
  checkedConditionSelectAll?: () => boolean;
};

export type LoadDataParams = {
  rowsPerPage: number;
  currentPage: number;
  sortDirection: "ASC" | "DESC";
  sortReference: string;
};

interface ListWithModalChangeSituationProps {
  typeOrder?: string;
  isOs?: boolean;
  hasStock?: boolean;
  order?: boolean;
  invoice?: boolean;
  situation?: boolean;
  budget?: boolean;
  billsToPay?: boolean;
  billsToReceive?: boolean;
  recurringExpenses?: boolean;
  products?: boolean;
  objectOrder?: ObjectOrder[];
  situationModalTitle?: string;
  onEdit?: (id: string) => void;
  onClone?: (id: string) => void;
  onStock?: (id: string) => void;
  headData: HeadDataBaseProps[];
  objectSituation?: Situation[];
  bodyData: BodyDataBaseProps[][];
  sortable?: boolean;
  onDelete?: (id: string, reason: string) => Promise<void>;
  onDeleteExpense?: (id: string) => Promise<void>;
  onDeleteAccountant?: (id: string) => Promise<void>;
  onReversal?: (id: string) => Promise<void>;
  onChangeSituation?(situation: Situation): Promise<void>;
  onThrowToBills?: (data: OnThrowToBillsData) => Promise<void>;
  onChangeOrder?(id: number, situation: OrdersSituation): Promise<void>;
  onStockMoved?(id: string, movedToStock: string): void;
  onBeforeOpenSendWhatsappModal?: (id: number) => boolean | Promise<boolean>;
  onBeforeOpenSendEmailModal?: (id: number) => boolean | Promise<boolean>;
  onBeforeAction?: (id: number) => boolean | Promise<boolean>;
  customButtons?: CustomButton[];
  actionsButtonShowCondition?: (id: string) => boolean;
  selectCheckbox?: SelectCheckbox;
  onClickSituation?: (id: string) => void;
  loadData?: (params: LoadDataParams) => any;
  totalCount?: number;
  triggerLoad?: boolean;
  setTriggerLoad?: React.Dispatch<React.SetStateAction<boolean>>;
  defaultSortReference?: string;
  defaultSortDirection?: "ASC" | "DESC";
  minWidth?: number;
  lastCell?: boolean;
  rowsPerPageProp?: number[];
  onPromotion?: (id: string) => void;
  onEditPromotion?: (id: string) => void;
  onDeletePromotion?: (id: string) => void;
  onSendPromotion?: (id: string) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  btlr: {
    borderTopLeftRadius: 0,
  },
  absoluteNav: {
    position: "absolute",
    top: "-50px",
    left: 0,
    background: "#fff",
    borderRadius: "0.42rem 0.42rem 0px 0px",
    height: "50px",
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

export const ListWithModalChangeSituation: React.FC<ListWithModalChangeSituationProps> = ({
  typeOrder,
  isOs,
  hasStock,
  order,
  invoice,
  onEdit,
  onClone,
  onStock,
  sortable,
  onDelete,
  onDeleteExpense,
  onDeleteAccountant,
  onReversal,
  headData,
  bodyData,
  objectOrder,
  onChangeOrder,
  onThrowToBills,
  onStockMoved,
  onBeforeOpenSendWhatsappModal,
  onBeforeOpenSendEmailModal,
  onBeforeAction,
  objectSituation,
  situation = false,
  budget = false,
  billsToPay = false,
  billsToReceive = false,
  recurringExpenses = false,
  products = false,
  onChangeSituation,
  situationModalTitle,
  customButtons,
  actionsButtonShowCondition,
  selectCheckbox,
  onClickSituation,
  loadData,
  totalCount,
  triggerLoad,
  setTriggerLoad,
  defaultSortReference,
  defaultSortDirection,
  minWidth = 250,
  lastCell = true,
  rowsPerPageProp,
  onPromotion,
  onEditPromotion,
  onDeletePromotion,
  onSendPromotion,
}) => {
  const { user } = useSelector((state: any) => state.auth);
  const { selectedCompany } = useCompanyBranch();
  const classes = useStyles();
  const rowsPerPageOptions = rowsPerPageProp ?? [15, 50, 100];

  const {
    location: { pathname },
    push: pushHistory,
  } = useHistory();

  const [stockLocationMove, setStockLocationMove] = useState("");
  const [observationMove, setObservationMove] = useState("");
  const [
    stockLocationMoveIsFromConsignment,
    setStockLocationMoveIsFromConsignment,
  ] = useState(false);
  const [isLoadingStockModal, setIsLoadingStockModal] = useState(false);
  const [isSubmittingStockModal, setIsSubmittingStockModal] = useState(false);

  const [stockLocationReverse, setStockLocationReverse] = useState("");
  const [observationReverse, setObservationReverse] = useState("");

  const [pagesWhatsapp, setPagesWhatsapp] = useState(0);
  const [rowsPerPageWhatsapp, setRowsPerPageWhatsapp] = useState(5);

  const [pages, setPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  //Sorting
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">(
    defaultSortDirection ?? "DESC"
  );
  const [sortReference, setSortReference] = useState(
    defaultSortReference ?? "id"
  );

  const [idToThrow, setIdToThrow] = useState(-1);
  const [indexToChange, setIndexToChange] = useState(-1);
  const [indexToDelete, setIndexToDelete] = useState("");
  const [indexSituation, setIndexSituation] = useState(0);

  const [selectedBillId, setSelectedBillId] = useState("");

  const [sending, setSending] = useState(false);
  const [infoEmail, setInfoEmail] = useState<EmailToCustomer>({
    refId: "",
    refName: "",
    toName: "",
    customerName: "",
    customerId: "",
    email: "",
    contactName: "",
    message: "",
    emailSubject: "",
  });
  const [nameContact, setNameContact] = useState("");
  const [emailsCustomer, setEmailsCustomer] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emails, setEmails] = useState<EmailsHistory[]>([]);
  const [whatsapp, setWhatsapp] = useState<WhatsappHistory[]>([]);

  // Lidando com visibilidade dos modais
  const [messageSuccess, setMessageSuccess] = useState("");
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [modalEmitNF, setModalEmitNF] = useState(false);
  const [modalEmitNFce, setModalEmitNFce] = useState(false);
  const [modalWhatsappOrder, setModalWhatsappOrder] = useState(false);
  const [modalWhatsappBill, setModalWhatsappBill] = useState(false);
  const [modalEmailOrder, setModalEmailOrder] = useState(false);
  const [modalStockOrder, setModalStockOrder] = useState(false);
  const [modalStockReverse, setModalStockReverse] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExpenseOpen, setIsModalExpenseOpen] = useState(false);
  const [isReversalModalOpen, setIsReversalModalOpen] = useState(false);
  const [isOrdersModalOpen, setIsOrdersModalOpen] = useState(false);
  const [reasonCancellation, setReasonCancellation] = useState("");
  const [reasonCancellationError, setReasonCancellationError] = useState(false);
  const [isModalDeleteAccoutant, setIsModalDeleteAccoutant] = useState(false);
  const tableRowRef = useRef<any>();

  const [idBill, setIdBill] = useState(0);
  const [idOrder, setIdOrder] = useState(0);

  const [orderSelected, setOrderSelected] = useState("");
  const [indexSelected, setIndexSelected] = useState(-1);

  const [pageChanged, setPageChanged] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const isBackEndPagination = !!(totalCount && loadData);

  const {
    isSituationModalOpen,
    setIsSituationModalOpen,
    viewOnly,
    setViewOnly,
    name,
    setName,
    supplier,
    setSupplier,
    dueDate,
    setDueDate,
    amount,
    setAmount,
    remaining,
    setRemaining,
    status,
    setStatus,
    paymentDate,
    setPaymentDate,
    payment,
    setPayment,
    bankAccount,
    setBankAccount,
    recordType,
    setRecordType,
    valueToPay,
    setValueToPay,
    fee,
    setFee,
    discount,
    setDiscount,
    tax,
    setTax,
    acc,
    setAcc,
    total,
    setTotal,
    writeOffValue,
    setWriteOffValue,
    partialPayments,
    setPartialPayments,
    isPaid,
    setIsPaid,
    validateDiscount,
    getChangedStatus,
  } = useChangeBillSituation();

  const {
    isThrowToBillsModalOpen,
    setIsThrowToBillsModalOpen,
    dreSubCategoryId,
    setDreSubCategoryId,
    centerCost,
    setCenterCost,
    installments,
    setInstallments,
    dreSubCategoryIdCommission,
    setDreSubCategoryIdCommission,
    centerCostCommission,
    setCenterCostCommission,
    installmentsCommission,
    setInstallmentsCommission,
    revenue,
    setRevenue,
    handleSetIsPaid,
    handleSetInstallmentBankAccount,
    handleSetInstallmentPaymentDate,
    handleSetIsPaidCommission,
    handleSetInstallmentBankAccountCommission,
    handleSetInstallmentPaymentDateCommission,
    handleSetInstallmentPaymentMethodCommission,
    handleSetBillInstallments,
  } = useThrowToBills();

  const downloadRef = useRef([]);
  downloadRef.current = bodyData.map(
    (data: any, index: number) => downloadRef.current[index] ?? createRef()
  );

  async function sendWhatsapp() {
    try {
      setSending(true);

      let nameCustomer = "";
      let cellCustomer = "";
      let orderName = "";
      let fileName = "";
      let order;
      let customer;

      if (typeOrder) {
        switch (typeOrder) {
          case "request":
            orderName = "Pedido";
            fileName = `Pedido-${idOrder}`;
            order = await axios.get(`/requests/${idOrder}`);
            customer = await axios.get(`/customer/${order.data.customerId}`);
            nameCustomer =
              customer.data.typePeople == "physical"
                ? customer.data.name
                : customer.data.corporateName;
            cellCustomer = customer.data.cell;
            break;
          case "budget":
            orderName = "Orçamento";
            fileName = `Orcamento-${idOrder}`;
            order = await axios.get(`/budgets/${idOrder}`);
            customer = await axios.get(`/customer/${order.data.customerId}`);
            nameCustomer =
              customer.data.typePeople == "physical"
                ? customer.data.name
                : customer.data.corporateName;
            cellCustomer = customer.data.cell;
            break;
          case "purchase":
            orderName = "Ordem de Compra";
            fileName = `OrdemDeCompra-${idOrder}`;
            order = await axios.get(`/purchase-order/${idOrder}`);
            customer = await axios.get(`/customer/${order.data.supplierId}`);
            nameCustomer =
              customer.data.typePeople == "physical"
                ? customer.data.name
                : customer.data.corporateName;
            cellCustomer = customer.data.cell;
            break;
          case "service":
            orderName = "Ordem de Serviço";
            fileName = `OrdemDeServico-${idOrder}`;
            order = await axios.get(`/service-order/${idOrder}`);
            customer = await axios.get(`/customer/${order.data.customerId}`);
            nameCustomer =
              customer.data.typePeople == "physical"
                ? customer.data.name
                : customer.data.corporateName;
            cellCustomer = customer.data.cell;
            break;
        }
      }

      const raw = {
        typeOrder: typeOrder,
        idOrder: idOrder,
        number: cellCustomer,
        nameCustomer,
        message: message,
        file: {
          urlFile: `${process.env.REACT_APP_PUBLIC_URL}/pdf/${typeOrder}/${idOrder}`,
          fileName: fileName,
        },
      };

      await axios.post(`/order/whatsapp/send`, raw);

      setShowModalSuccess(true);
      setMessageSuccess("Mensagem enviada com sucesso!");
      setMessage("");
    } catch (error) {
      console.log("error", error);
      setMsgError("Falha ao enviar a mensagem!");
      setShowModalError(true);
    }

    setSending(false);
    setModalWhatsappOrder(false);
  }

  async function handleOpenSendWhatsappModal(
    refId: number,
    type: "bill" | "order"
  ) {
    if (onBeforeOpenSendWhatsappModal) {
      const canOpenModal = await onBeforeOpenSendWhatsappModal(refId);
      if (!canOpenModal) {
        return;
      }
    }

    setPagesWhatsapp(0);

    if (type === "bill") {
      setIdBill(refId);
      getWhatsMessages(refId);
      setMessage("Esta é uma mensagem para envio do boleto");
      setModalWhatsappBill(true);
    } else {
      setIdOrder(refId);
      getWhatsMessages(refId);
      setMessage("Esta é uma mensagem para envio do pdf");
      setModalWhatsappOrder(true);
    }
  }

  async function sendWhatsappBill() {
    setSending(true);

    try {
      const bill = await axios.get(`/billsToReceive/${idBill}`);
      const customer = await axios.get(`/customer/${bill.data.customer}`);

      let file = undefined;

      if (bill.data.recordType === "billet") {
        const billetFilename = await BillToReceiveService.getBilletDocumentFilename(
          bill.data
        );
        bill.data.billetDocument = billetFilename;

        file = {
          urlFile: await BillToReceiveService.getBilletFileUrl(bill.data),
          fileName: `Conta-a-receber-${bill.data.id}.pdf`,
        };
      }

      const rawWhatsapp = {
        idBill: bill.data.id,
        typeBill: "receive",
        number: customer.data.cell,
        message: message,
        file,
      };

      await axios.post(`/bill/whatsapp/send`, rawWhatsapp);

      setShowModalSuccess(true);
      setMessageSuccess("Mensagem enviada com sucesso!");
      setMessage("");
    } catch (error) {
      console.log("error", error);
      setMsgError("Falha ao enviar a mensagem!");
      setShowModalError(true);
    }

    setSending(false);
    setModalWhatsappBill(false);
  }

  async function handleOpenSendEmailModal(
    refId: number,
    type: "bill" | "order"
  ) {
    if (onBeforeOpenSendEmailModal) {
      const canOpenModal = await onBeforeOpenSendEmailModal(refId);
      if (!canOpenModal) {
        return;
      }
    }

    if (type === "bill") {
      setIdBill(refId);
      getEmailsBills(refId);
    } else {
      setIdOrder(refId);
      getEmails(refId);
    }
    setModalEmailOrder(true);
    setInfoEmail({
      refId: String(refId),
      refName: "",
      toName: "",
      customerName: "",
      customerId: "",
      email: "",
      contactName: "",
      message: "Este é um email para envio do pdf",
      emailSubject: subject,
    });
  }

  async function sendEmail() {
    setSending(true);

    var nameCustomer = "";
    var orderName = "";
    var order;

    if (typeOrder) {
      switch (typeOrder) {
        case "request":
          orderName = "Pedido";
          order = await axios.get(`/requests/${idOrder}`);
          var customer = await axios.get(`/customer/${order.data.customerId}`);
          nameCustomer =
            customer.data.typePeople == "physical"
              ? customer.data.name
              : customer.data.corporateName;
          break;
        case "budget":
          orderName = "Orçamento";
          order = await axios.get(`/budgets/${idOrder}`);
          var customer = await axios.get(`/customer/${order.data.customerId}`);
          nameCustomer =
            customer.data.typePeople == "physical"
              ? customer.data.name
              : customer.data.corporateName;
          break;
        case "purchase":
          orderName = "Ordem e Compra";
          order = await axios.get(`/purchase-order/${idOrder}`);
          var customer = await axios.get(`/customer/${order.data.supplierId}`);
          nameCustomer =
            customer.data.typePeople == "physical"
              ? customer.data.name
              : customer.data.corporateName;
          break;
        case "service":
          orderName = "Ordem e Serviço";
          order = await axios.get(`/service-order/${idOrder}`);
          var customer = await axios.get(`/customer/${order.data.customerId}`);
          nameCustomer =
            customer.data.typePeople == "physical"
              ? customer.data.name
              : customer.data.corporateName;
          break;
      }
    } else {
      if (billsToPay) {
        orderName = "Contas a pagar";
        order = await axios.get(`/billsToReceive/${idBill}`);
        var customer = await axios.get(`/customer/${order.data.customer}`);
        nameCustomer =
          customer.data.typePeople == "physical"
            ? customer.data.name
            : customer.data.corporateName;
      } else if (billsToReceive) {
        orderName = "Contas a receber";
        order = await axios.get(`/billsToReceive/${idBill}`);
        var customer = await axios.get(`/customer/${order.data.customer}`);
        nameCustomer =
          customer.data.typePeople == "physical"
            ? customer.data.name
            : customer.data.corporateName;
      }
    }

    var otherMails = infoEmail.email.split(";");

    for (var index = 0; index < otherMails.length; index++) {
      // tirando espaços em branco em volta do email
      otherMails[index] = otherMails[index].trim();
    }

    try {
      if (!typeOrder) {
        const responseBill = await axios.get<BillsToReceive>(
          `/billsToReceive/${idBill}`
        );
        const bill = responseBill.data;
        if (bill) {
          await sendBillEmailToCustomer(otherMails, {
            bill,
            customerId: bill.customer,
            customerName: nameCustomer,
            contactName: infoEmail.contactName,
            message: infoEmail.message,
            refName: orderName,
            subject: infoEmail.emailSubject,
          });
        }
      } else {
        const raw = {
          to: otherMails,
          subject: infoEmail.emailSubject,
          name: orderName,
          type: typeOrder,
          id: idOrder,
          nameContact: infoEmail.contactName,
          nameCustomer: nameCustomer,
          message: infoEmail.message,
        };

        await axios.post(`/${"email-order"}/send`, raw);
      }

      setShowModalSuccess(true);
      setMessageSuccess("E-mail enviado com sucesso!");
    } catch (error) {
      setMsgError(error.response?.data?.message ?? "Falha ao enviar o e-mail!");
      setShowModalError(true);
    }

    setSending(false);
    setModalEmailOrder(false);
    setNameContact("");
    setEmailsCustomer("");
    setMessage("");
    setEmails([]);
  }

  async function getBillet(id: number) {
    const { data } = await axios.get<BillsToReceive>(`/billsToReceive/${id}`);

    if (data.recordType !== "billet" || data.payment !== "Boleto") {
      return;
    }

    const billetUrl = await BillToReceiveService.getBilletFileUrl(data);

    window.open(billetUrl);
  }

  async function getWhatsMessages(id?: number) {
    var whatsappOrder = await axios.get(
      `/${typeOrder ? "order" : "bill"}/whatsapp/${typeOrder ??
        (billsToPay ? "pay" : "receive")}/${id ?? idOrder}`
    );
    setWhatsapp(whatsappOrder.data);
  }

  async function getEmails(id?: number) {
    setEmails([]);
    var emailsOrder = await axios.get(
      `/email-order/${typeOrder}/${id ?? idOrder}`
    );
    var emailCustomer = "";

    if (typeOrder) {
      switch (typeOrder) {
        case "request":
          var order = await axios.get(`/requests/${id ?? idOrder}`);
          var customer = await axios.get(`/customer/${order.data.customerId}`);
          emailCustomer = customer.data.email;
          break;
        case "budget":
          var order = await axios.get(`/budgets/${id ?? idOrder}`);
          var customer = await axios.get(`/customer/${order.data.customerId}`);
          emailCustomer = customer.data.email;
          break;
        case "purchase":
          var order = await axios.get(`/purchase-order/${id ?? idOrder}`);
          var customer = await axios.get(`/customer/${order.data.supplierId}`);
          emailCustomer = customer.data.email;
          break;
        case "service":
          var order = await axios.get(`/service-order/${id ?? idOrder}`);
          var customer = await axios.get(`/customer/${order.data.customerId}`);
          emailCustomer = customer.data.email;
          break;
      }
    }

    setEmails(emailsOrder.data);
    setEmailsCustomer(emailCustomer);
    setInfoEmail((prevState) => ({
      ...prevState,
      email: emailCustomer || "",
    }));
  }

  async function getEmailsBills(id?: number) {
    setEmails([]);
    var typeBill = "";
    if (billsToReceive) {
      typeBill = "receive";
    }

    if (billsToPay) {
      typeBill = "pay";
    }

    var emailsBills = await axios.get(
      `/email-bill/${typeBill}/${id ?? idBill}`
    );
    var emailCustomer = "";

    if (billsToPay) {
      var order = await axios.get(`/billsToReceive/${id ?? idBill}`);
      var customer = await axios.get(`/customer/${order.data.customer}`);
      emailCustomer = customer.data.email;
    } else if (billsToReceive) {
      var order = await axios.get(`/billsToReceive/${id ?? idBill}`);
      var customer = await axios.get(`/customer/${order.data.customer}`);
      emailCustomer = customer.data.email;
    }

    setEmails(emailsBills.data);
    setEmailsCustomer(emailCustomer);
    setInfoEmail((prevState) => ({
      ...prevState,
      email: emailCustomer || "",
    }));
  }

  const handleLoadData = useCallback(async () => {
    if (
      (pageChanged && loadData) ||
      (loadData && triggerLoad && setTriggerLoad)
    ) {
      setIsLoadingData(true);
      await loadData({
        rowsPerPage,
        currentPage: pages,
        sortDirection,
        sortReference,
      });
      setIsLoadingData(false);

      if (pageChanged) {
        setPageChanged(false);
      }

      if (triggerLoad && setTriggerLoad) {
        setPages(0);
        setTriggerLoad(false);
      }
    }
  }, [
    rowsPerPage,
    pages,
    triggerLoad,
    pageChanged,
    sortDirection,
    sortReference,
  ]);

  useEffect(() => {
    handleLoadData();
  }, [rowsPerPage, pages, triggerLoad, pageChanged]);

  useEffect(() => {
    switch (typeOrder) {
      case "request":
        setSubject("Pedido");
        break;
      case "budget":
        setSubject("Orçamento");
        break;
      case "purchase":
        setSubject("Ordem de compra");
        break;
      case "service":
        setSubject("Ordem de serviço");
        break;
    }

    if (billsToPay) {
      setSubject("Contas a pagar");
    }

    if (billsToReceive) {
      setSubject("Contas a receber");
    }
  }, []);

  useEffect(() => {
    if (situation || order || invoice) {
      headData.forEach((head, index) => {
        if (head.situation) setIndexSituation(index);
      });
    }
  }, []);

  useEffect(() => {
    function getSelectedBillInformation() {
      if (indexToChange >= 0) {
        if (objectSituation) {
          // const aux = objectSituation[indexToChange];
          const aux = objectSituation.find(
            (obj) => String(obj.id) === selectedBillId
          );

          if (!aux) {
            return;
          }

          const [year, month, day] = aux.dueDate.split("-");

          if (!month) {
            setDueDate(aux.dueDate);
          } else {
            const newDate = new Date(`${year}-${month}-${day}`);
            const formatedDate = formatDate(newDate);
            setDueDate(formatedDate);
          }

          if (aux.status === "paid") {
            setViewOnly(true);
            setTotal(aux.totalPaid);
            setAcc(formatCurrency(aux.accValue));
            setTax(formatCurrency(aux.taxValue));
            setFee(formatCurrency(aux.feeValue));
            setDiscount(formatCurrency(aux.discountValue));
            setValueToPay(formatCurrency(aux.amount));
          } else {
            setViewOnly(false);
            setTotal(aux.remaining);
            setAcc("R$0,00");
            setTax("R$0,00");
            setFee("R$0,00");
            setDiscount("R$0,00");
            setValueToPay(formatCurrency(aux.remaining));
          }

          setName(aux.name);
          setStatus(aux.status);
          setAmount(aux.amount);
          setPayment(aux.payment);
          setSupplier(aux.beneficiaryName ?? aux.customer);
          setRemaining(aux.remaining);
          setBankAccount(aux.bankAccount);
          setRecordType(aux.recordType);
        }
      }
    }

    getSelectedBillInformation();
  }, [indexToChange, selectedBillId, objectSituation]);

  useEffect(() => {
    if (objectOrder && idToThrow >= 0) {
      if (objectOrder.length > 0) {
        const foundObj = objectOrder.find((obj) => obj.id === idToThrow);

        if (!foundObj) return;

        handleSetBillInstallments({
          order,
          typeOrder,
          orderObject: foundObj,
        });
      }
    }
  }, [objectOrder, idToThrow]);

  // Modal contas a pagar e receber
  useLayoutEffect(() => {
    if (objectSituation && indexToChange >= 0) {
      // const aux = objectSituation[indexToChange];
      const aux = objectSituation.find(
        (obj) => String(obj.id) === selectedBillId
      );
      if (!aux) {
        return;
      }
      const valueNumber = formatToFloat(valueToPay);
      const feeNumber = formatToFloat(fee);
      const taxNumber = formatToFloat(tax);
      const accNumber = formatToFloat(acc);
      const discountNumber = formatToFloat(discount);

      validateDiscount({
        valueNumber,
        feeNumber,
        taxNumber,
        accNumber,
        discountNumber,
      });
    }
  }, [valueToPay, fee, tax, acc, discount, indexToChange]);

  const handleSort = useCallback((reference: string, active: boolean) => {
    if (active) {
      setSortDirection((prevState) => (prevState === "ASC" ? "DESC" : "ASC"));
    }

    setSortReference(reference);

    if (setTriggerLoad) {
      setTriggerLoad(true);
    }
  }, []);

  // Mundando página
  const handleChangePage = useCallback((next: number) => {
    setPages(next);
    setPageChanged(true);
  }, []);

  // Mudnando linhas por página
  const handleRowsPerPage = useCallback((value: number) => {
    setRowsPerPage(value);
    setPages(0);
    setPageChanged(true);
  }, []);

  const handleChangePageWhatsapp = useCallback((next: number) => {
    setPagesWhatsapp(next);
  }, []);

  const handleRowsPerPageWhatsapp = useCallback((value: number) => {
    setRowsPerPageWhatsapp(value);
    setPagesWhatsapp(0);
  }, []);

  // Funções para lidar com modais

  const handleSetSituationModal = useCallback(
    (index: number, id: string) => {
      setIndexToChange(index);
      setSelectedBillId(id);

      if (order) {
        setIsOrdersModalOpen(true);
      }

      if (situation) {
        updateValuesBill(Number(id));
        setIsSituationModalOpen(true);
      }
    },
    [order, situation]
  );

  async function updateValuesBill(id: number) {
    var bill = billsToReceive
      ? await axios.get(`/billsToReceive/${id}`)
      : await axios.get(`/billsToPay/${id}`);

    var payedDate = billsToReceive
      ? BillToReceiveService.getBillPaymentDate(bill.data)
      : BillToPayService.getBillPaymentDate(bill.data);

    const defaultPaymentDate =
      bill.data.dueDate || format(Date.now(), "yyyy-MM-dd");

    setPaymentDate(payedDate ?? defaultPaymentDate);
    setFee(bill.data.feeValue);
    setDiscount(bill.data.discountValue);
    setTax(bill.data.taxValue);
    setAcc(bill.data.accValue);
    setIsPaid(true);
    setWriteOffValue(bill.data.remaining);
    setPartialPayments(bill.data.partialPayments);

    if (bill.data.status == "paid") {
      setTotal(bill.data.totalPaid);
    }
  }

  const handleReversalModal = useCallback((index: number, id: string) => {
    setIndexToChange(index);
    setSelectedBillId(id);

    setIsReversalModalOpen(true);
  }, []);

  const handleChangeSituation = useCallback(async () => {
    if (onChangeSituation) {
      if (objectSituation && indexToChange >= 0) {
        if (bankAccount) {
          const billObj = objectSituation.find(
            (obj) => String(obj.id) === selectedBillId
          );
          if (!billObj) {
            return;
          }
          // const { id, dueDate } = objectSituation[indexToChange];
          const { id, dueDate, partialPayments } = billObj;
          const changedRemaining = remaining - formatToFloat(valueToPay);
          const changedStatus = getChangedStatus(changedRemaining);

          await onChangeSituation({
            id,
            name,
            amount,
            dueDate,
            payment,
            bankAccount,
            recordType,
            customer: supplier,
            status: changedStatus,
            remaining: changedRemaining,
            accValue: formatToFloat(acc),
            taxValue: formatToFloat(tax),
            discountValue: formatToFloat(discount),
            feeValue: formatToFloat(fee),
            totalPaid: total,
            payedDate: paymentDate,
            partialPayments: partialPayments,
            writeOffValue,
          });

          handleCloseBillSituationModal();
          setSelectedBillId("");
        } else {
          alert("Selecione a conta bancária!");
        }
      }
    }
  }, [
    objectSituation,
    indexToChange,
    writeOffValue,
    valueToPay,
    isPaid,
    supplier,
    remaining,
    bankAccount,
    payment,
    paymentDate,
    selectedBillId,
    total,
    recordType,
  ]);

  function handleCloseBillSituationModal() {
    setIsSituationModalOpen(false);
    setBankAccount("");
    setIndexToChange(-1);
  }

  const handleChangeOrder = useCallback(async () => {
    if (onChangeOrder) {
      if (objectOrder && indexToChange >= 0) {
        const { id } = objectOrder[indexToChange];

        await onChangeOrder(id, {
          statusSituation: status,
          commentsSituation: name,
          dateSituation: format(Date.now(), "yyyy-MM-dd"),
        });

        setIsOrdersModalOpen(false);
      }
    }
  }, [objectOrder, indexToChange, status, name]);

  const handleClickThrowToBills = async (
    row: BodyDataBaseProps[],
    index: number
  ) => {
    const idCell = row.find((cell) => cell.id);
    const id = Number(idCell?.value);

    if (onBeforeAction) {
      const canDoAction = await onBeforeAction(id);
      if (!canDoAction) {
        return;
      }
    }

    setIndexToChange(index);
    setIdToThrow(id);
    setIsThrowToBillsModalOpen(true);
  };

  const handleClickEdit = (row: BodyDataBaseProps[]) => {
    row.forEach((item) => {
      if (item.id && onEdit) {
        onEdit(item.value);
      }
    });
  };

  const handleClickStock = (row: BodyDataBaseProps[]) => {
    row.forEach((item) => {
      if (item.id && !!onStock) {
        onStock(item.value);
      }
    });
  };

  const handleClickDelete = (row: BodyDataBaseProps[]) => {
    row.forEach((item) => {
      if (item.id) {
        setIndexToDelete(item.value);
        setIsModalOpen(true);
      }
    });
  };

  const handleClickExpenseDelete = (row: BodyDataBaseProps[]) => {
    row.forEach((item) => {
      if (item.id) {
        setIndexToDelete(item.value);
        setIsModalExpenseOpen(true);
      }
    });
  };

  const handleClickPromotion = (row: BodyDataBaseProps[]) => {
    row.forEach((item) => {
      if (item.id && onPromotion) {
        onPromotion(item.value);
      }
    });
  };

  const handleClickEditPromotion = (row: BodyDataBaseProps[]) => {
    row.forEach((item) => {
      if (item.id && onEditPromotion) {
        onEditPromotion(item.value);
      }
    });
  };

  const handleClickDeletePromotion = (row: BodyDataBaseProps[]) => {
    row.forEach((item) => {
      if (item.id && onDeletePromotion) {
        onDeletePromotion(item.value);
      }
    });
  };

  const handleClickSendPromotion = (row: BodyDataBaseProps[]) => {
    row.forEach((item) => {
      if (item.id && onSendPromotion) {
        onSendPromotion(item.value);
      }
    });
  };

  const handleChangeSelectAllCheckbox = (checked: boolean) => {
    if (!selectCheckbox || !selectCheckbox.onChangeSelectAll) return;

    selectCheckbox.onChangeSelectAll(checked);
  };

  const checkedConditionSelectAllCheckbox = () => {
    if (!selectCheckbox || !selectCheckbox.checkedConditionSelectAll)
      return undefined;

    return selectCheckbox.checkedConditionSelectAll();
  };

  const handleChangeSelectCheckbox = (
    checked: boolean,
    row: BodyDataBaseProps[]
  ) => {
    if (!selectCheckbox || !selectCheckbox.onChange) return;

    const id = row.find((r) => r.id)?.value ?? "";

    selectCheckbox.onChange(checked, id);
  };

  const checkedConditionSelectCheckbox = (row: BodyDataBaseProps[]) => {
    if (!selectCheckbox || !selectCheckbox.checkedCondition) return undefined;

    const id = row.find((r) => r.id)?.value ?? "";

    return selectCheckbox.checkedCondition(id);
  };

  const canShowSelectCheckbox = (row: BodyDataBaseProps[]) => {
    if (!selectCheckbox || !selectCheckbox.showCondition) return false;

    const id = row.find((r) => r.id)?.value ?? "";

    return selectCheckbox.showCondition(id);
  };

  const handleOnDelete = async () => {
    try {
      if (!reasonCancellation) {
        setReasonCancellationError(true);
        alert("Preencha o motivo do cancelamento!");
        return;
      }

      if (onDelete) {
        await onDelete(indexToDelete, reasonCancellation);
      }

      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnDeleteExpense = async () => {
    try {
      if (onDeleteExpense) {
        await onDeleteExpense(indexToDelete);
      }

      setIsModalExpenseOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnDeleteAccoutant = async () => {
    try {
      if (onDeleteAccountant) {
        await onDeleteAccountant(indexToDelete);
      }

      setIsModalDeleteAccoutant(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnReversal = async () => {
    try {
      if (onReversal) {
        await onReversal(selectedBillId);
      }

      setIsReversalModalOpen(false);
      setSelectedBillId("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnThrowToBills = useCallback(async () => {
    if (onThrowToBills) {
      await onThrowToBills({
        revenue,
        subCategoryId: dreSubCategoryId,
        centerCost,
        installments,
        installmentsCommission,
        centerCostCommission,
        subCategoryIdCommission: dreSubCategoryIdCommission,
        id: idToThrow,
      });

      setIsThrowToBillsModalOpen(false);
    }
  }, [
    installments,
    revenue,
    centerCost,
    idToThrow,
    dreSubCategoryId,
    installmentsCommission,
    centerCostCommission,
    dreSubCategoryIdCommission,
  ]);

  const renderOrderHistory = () => {
    if (objectOrder && indexToChange >= 0) {
      if (objectOrder.length > 0) {
        const aux = objectOrder[indexToChange].situation;
        if (aux) {
          if (aux.length > 0) {
            return aux.map((order, index) => {
              let date;
              const [year, month, day] = order.dateSituation.split("-");

              if (!month) {
                date = dueDate;
              } else {
                const newDate = new Date(`${year}-${month}-${day}`);
                date = format(newDate, "dd/MM/yyyy");
              }

              const status =
                order.statusSituation === "open"
                  ? "Em aberto"
                  : order.statusSituation === "attended"
                  ? "Atendido"
                  : order.statusSituation === "canceled"
                  ? "Cancelado"
                  : order.statusSituation === "progress"
                  ? "Em andamento"
                  : order.statusSituation === "aproved"
                  ? "Aprovado"
                  : "";

              return (
                <TableRow key={index}>
                  <TableCell>{date}</TableCell>
                  <TableCell>{order.commentsSituation}</TableCell>
                  <TableCell>{status}</TableCell>
                </TableRow>
              );
            });
          }
        }
      }
    }
  };

  const handleOpenLaunchInStockModal = useCallback(
    async (refId: number) => {
      if (onBeforeAction) {
        const canDoAction = await onBeforeAction(refId);
        if (!canDoAction) {
          return;
        }
      }

      setModalStockOrder(true);
      getStockMoved(String(refId));
    },
    [onBeforeAction]
  );

  async function handleLaunchInStock() {
    setIsSubmittingStockModal(true);

    if (stockLocationMove) {
      let products: any[] = [];
      if (typeOrder == "service" && isOs) {
        const { data: foundServiceOrder } = await api.get(
          `/service-order/${orderSelected}`
        );
        products = JSON.parse(foundServiceOrder?.products || "[]");
      } else if (typeOrder == "request") {
        const { data: foundRequest } = await api.get(
          `/requests/${orderSelected}`
        );
        products = JSON.parse(foundRequest?.products || "[]");
      } else if (typeOrder == "purchase") {
        const { data: foundPurchase } = await api.get(
          `/purchase-order/${orderSelected}`
        );
        products = JSON.parse(foundPurchase?.products || "[]");
      } else if (typeOrder == "entry") {
        const { data: foundEntry } = await api.get(`/entry/${orderSelected}`);
        products = JSON.parse(foundEntry?.products || "[]");
      }

      const launchInStockResult = await StockService.launchInStock({
        products,
        typeOrder,
        isOs,
        orderSelected,
        stockLocationMove,
        observationMove,
      });

      if (!launchInStockResult.success) {
        alert(launchInStockResult.message);
        setIsSubmittingStockModal(false);
        return;
      }

      if (onStockMoved) {
        onStockMoved(orderSelected, "y");
      }

      setStockLocationMove("");
      setObservationMove("");
      setOrderSelected("");
      setModalStockOrder(false);
      setIndexToChange(indexSelected);
      setIndexSelected(-1);
    } else {
      alert("Selecione em qual estoque deseja lançar!");
    }

    setIsSubmittingStockModal(false);
  }

  async function reverseVariationsInStock(product: any) {
    const { data } = await axios.get(`/variations/${product.INSTANCE.id}`);

    const dateStockMove = new Date();
    const month =
      dateStockMove.getMonth() + 1 < 10
        ? "0" + (dateStockMove.getMonth() + 1)
        : dateStockMove.getMonth() + 1;
    const day =
      dateStockMove.getDate() < 10
        ? "0" + dateStockMove.getDate()
        : dateStockMove.getDate();

    const stockResult = await axios.get(
      `/stockLocation/${stockLocationReverse}`
    );
    const stockName = stockResult.data.name ?? "";

    StockService.getTotalStock(data.productId, data.id).then((totalStock) => {
      StockService.getTotalLocalStock(
        data.productId,
        stockLocationReverse,
        data.id
      ).then((totalLocalStock) => {
        let rawHistoric: any = {
          productId: Number(data.productId),
          date: dateStockMove.getFullYear() + "-" + month + "-" + day,
          observation: observationReverse ?? null,
          stockId: Number(stockLocationReverse),
          stockName: stockName,
          value: formatToFloat(data.saleValue)
            ? formatToFloat(data.saleValue)
            : null,
          hasVariation: "y",
          variationId: data.id,
          requestOrPurchaseId: Number(orderSelected),
          type: getTypeOrderText(typeOrder),
        };

        const isEntry = getTypeOrderIsEntry(typeOrder);
        if (isEntry) {
          rawHistoric.entry = product.amount;
          rawHistoric.totalLocation =
            totalLocalStock.totalBalance + product.amount;
          rawHistoric.total = totalStock.totalBalance + product.amount;
        } else {
          rawHistoric.output = product.amount;
          rawHistoric.totalLocation =
            totalLocalStock.totalBalance - product.amount;
          rawHistoric.total = totalStock.totalBalance - product.amount;
        }

        if (rawHistoric) {
          axios.post("/stockProductHistoric", rawHistoric);
        }
      });
    });
  }

  async function reverseProductKitInStock(product: any) {
    const { data } = await axios.get(`/products/${product.INSTANCE.id}`);

    const products = JSON.parse(data.kit);

    const dateStockMove = new Date();
    const month =
      dateStockMove.getMonth() + 1 < 10
        ? "0" + (dateStockMove.getMonth() + 1)
        : dateStockMove.getMonth() + 1;
    const day =
      dateStockMove.getDate() < 10
        ? "0" + dateStockMove.getDate()
        : dateStockMove.getDate();

    products.map((value: any, index: number) => {
      StockService.getTotalStock(value.productId).then((totalStock) => {
        StockService.getTotalLocalStock(
          value.productId,
          stockLocationMove
        ).then((totalLocalStock) => {
          axios
            .get(`/products/${value.productId}`)
            .then((productResponse: any) => {
              let rawHistoric: any = {
                productId: productResponse.data.id,
                date: dateStockMove.getFullYear() + "-" + month + "-" + day,
                observation: observationReverse ?? null,
                stockId: Number(stockLocationReverse),
                value: productResponse.data.saleValue
                  ? formatToFloat(productResponse.data.saleValue)
                  : null,
                hasVariation: "n",
                requestOrPurchaseId: Number(orderSelected),
                type: getTypeOrderText(typeOrder),
              };

              const isEntry = getTypeOrderIsEntry(typeOrder);
              if (isEntry) {
                rawHistoric.entry = value.productCount;
                rawHistoric.totalLocation =
                  totalLocalStock.totalBalance + value.productCount;
                rawHistoric.total =
                  totalStock.totalBalance + value.productCount;
              } else {
                rawHistoric.output = value.productCount;
                rawHistoric.totalLocation =
                  totalLocalStock.totalBalance - value.productCount;
                rawHistoric.total =
                  totalStock.totalBalance - value.productCount;
              }

              if (rawHistoric) {
                axios.post("/stockProductHistoric", rawHistoric);
              }
            });
        });
      });
    });
  }

  async function reverseStock() {
    setIsSubmittingStockModal(true);
    const dateStockMove = new Date();
    const month =
      dateStockMove.getMonth() + 1 < 10
        ? "0" + (dateStockMove.getMonth() + 1)
        : dateStockMove.getMonth() + 1;
    const day =
      dateStockMove.getDate() < 10
        ? "0" + dateStockMove.getDate()
        : dateStockMove.getDate();

    const request = await axios.get(
      typeOrder == "service" && isOs
        ? `/service-order/${orderSelected}`
        : typeOrder == "request"
        ? `/requests/${orderSelected}`
        : typeOrder == "entry"
        ? `/entry/${orderSelected}`
        : `/purchase-order/${orderSelected}`
    );

    const products = JSON.parse(request.data.products);

    if (!verifyProductsJSON(products)) {
      alert("Não há produtos para movimentar!");
      setIsSubmittingStockModal(false);
      return;
    }

    const stockResult = await axios.get(
      `/stockLocation/${stockLocationReverse}`
    );
    const stockName = stockResult.data.name ?? "";

    products.map((value: any, index: number) => {
      if (value.INSTANCE.hasVariation == "y") {
        reverseVariationsInStock(value);
      } else if (value.INSTANCE.type == "kit") {
        reverseProductKitInStock(value);
      } else {
        StockService.getTotalStock(value.INSTANCE.id).then((totalStock) => {
          StockService.getTotalLocalStock(
            value.INSTANCE.id,
            stockLocationReverse
          ).then((totalLocalStock) => {
            let rawHistoric: any = {
              productId: Number(value.INSTANCE.id),
              date: dateStockMove.getFullYear() + "-" + month + "-" + day,
              observation: observationReverse ?? null,
              stockId: Number(stockLocationReverse),
              stockName: stockName,
              value: value.unitaryValue ? value.unitaryValue : null,
              hasVariation: "n",
              requestOrPurchaseId: Number(orderSelected),
              type: getTypeOrderText(typeOrder),
            };

            const isEntry = getTypeOrderIsEntry(typeOrder);
            if (isEntry) {
              rawHistoric.output = value.amount;
              rawHistoric.totalLocation =
                totalLocalStock.totalBalance - value.amount;
              rawHistoric.total = totalStock.totalBalance - value.amount;
            } else {
              rawHistoric.entry = value.amount;
              rawHistoric.totalLocation =
                totalLocalStock.totalBalance + value.amount;
              rawHistoric.total = totalStock.totalBalance + value.amount;
            }

            if (rawHistoric) {
              axios.post("/stockProductHistoric", rawHistoric);
            }
          });
        });
      }
    });

    const rawOrder = {
      movedToStock: "n",
    };

    if (typeOrder == "service" && isOs) {
      axios.put(`/service-order/${orderSelected}`, rawOrder);
    } else if (typeOrder == "request") {
      axios.put(`/requests/${orderSelected}`, rawOrder);
    } else if (typeOrder == "purchase") {
      axios.put(`/purchase-order/${orderSelected}`, rawOrder);
    } else if (typeOrder == "entry") {
      axios.put(`/entry/${orderSelected}`, rawOrder);
    }

    if (onStockMoved) {
      onStockMoved(orderSelected, "n");
    }

    setStockLocationReverse("");
    setObservationReverse("");
    setOrderSelected("");
    setModalStockReverse(false);
    setIndexToChange(indexSelected);
    setIndexSelected(-1);
    setIsSubmittingStockModal(false);
  }

  async function getStockMoved(orderId: any) {
    setIsLoadingStockModal(true);
    setStockLocationMoveIsFromConsignment(false);

    let stockId = "";

    const { data } = await axios.get(
      `/stockProductHistoric/order/${orderId}/${typeOrder}`
    );
    stockId = String(data.stockId || "");

    if (!stockId) {
      // Se for uma venda, verificar se é consignação, se for, pegar o estoque selecionado
      if (typeOrder == "request") {
        const { data: foundRequest } = await axios.get<Request>(
          `/requests/${orderId}`
        );
        if (foundRequest.sellerConsignmentStockLocationId) {
          stockId = String(foundRequest.sellerConsignmentStockLocationId);
          setStockLocationMoveIsFromConsignment(true);
        }
      }
    }

    setStockLocationReverse(stockId);
    setStockLocationMove(stockId);

    setOrderSelected(orderId);
    setIsLoadingStockModal(false);
  }

  async function getDefaultStock(orderId: any) {
    const { data } = await axios.get(
      typeOrder == "request"
        ? `/requests/${orderSelected}`
        : `/purchase-order/${orderSelected}`
    );
    var products = data.length ? JSON.parse(data[0].products) : [];

    var defaultStock = "";
    products.map((value: any, index: number) => {
      if (value.INSTANCE.hasVariation == "y") {
        axios
          .get(`/variations/product/${value.INSTANCE.id}`)
          .then((variation: any) => {
            if (index == 0) {
              defaultStock = variation.id;
            } else {
              if (variation.id != defaultStock) {
                defaultStock = "";
              }
            }
          });
      } else {
        if (index == 0) {
          defaultStock = value.INSTANCE.id;
        } else {
          if (value.INSTANCE.id != defaultStock) {
            defaultStock = "";
          }
        }
      }
    });

    setStockLocationMove(defaultStock);
  }

  const handleClickEmitNf = useCallback(
    async (refId: number) => {
      if (onBeforeAction) {
        const canDoAction = await onBeforeAction(refId);
        if (!canDoAction) {
          return;
        }
      }

      setIdOrder(refId);
      setModalEmitNF(true);
    },
    [onBeforeAction]
  );
  const handleClickEmitNfce = useCallback(
    async (refId: number) => {
      if (onBeforeAction) {
        const canDoAction = await onBeforeAction(refId);
        if (!canDoAction) {
          return;
        }
      }

      setIdOrder(refId);
      setModalEmitNFce(true);
    },
    [onBeforeAction]
  );

  function redirectNF() {
    switch (typeOrder) {
      case "service":
        pushHistory(`/notas-fiscais-servico/${idOrder}/adicionar`);
        break;
      case "request":
        pushHistory(`/notas-fiscais/pedido/${idOrder}/adicionar`);
        break;
      case "purchase":
        pushHistory(`/notas-fiscais/ordem-de-compra/${idOrder}/adicionar`);
        break;
    }
  }

  function redirectNFce() {
    switch (typeOrder) {
      case "service":
        pushHistory(`/notas-fiscais-servico/${idOrder}/adicionar`);
        break;
      case "request":
        pushHistory(`/notas-fiscais-consumidor/pedido/${idOrder}/adicionar`);
        break;
      case "purchase":
        pushHistory(`/notas-fiscais/ordem-de-compra/${idOrder}/adicionar`);
        break;
    }
  }

  function getBillCreatedCellValue(cellsArray: BodyDataBaseProps[]) {
    const billCreatedCell = cellsArray.find(
      (cell) => cell.for === "billCreated"
    );
    return billCreatedCell?.value;
  }

  function getMovedToStockCellValue(cellsArray: BodyDataBaseProps[]) {
    const movedToStockCell = cellsArray.find(
      (cell) => cell.for === "movedToStock"
    );
    return movedToStockCell?.value;
  }

  function handleLastTableColumn() {
    const trChildrens = tableRowRef.current.children;

    trChildrens.forEach((th: any) => {
      if (th.children.length === 0) {
        th.classList.add("d-none");
      }
    });
  }

  const invoiceColumn = 5;
  const showInvoiceButton = (value?: string) => {
    return !value;
  };

  useEffect(() => {
    if (!lastCell) {
      handleLastTableColumn();
    }
  }, [lastCell]);

  return (
    <Paper>
      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />

      {/* Modal de deleção do contador */}
      <Modal
        centered
        aria-labelledby="contained-modal-warning"
        show={isModalDeleteAccoutant}
        onHide={() => setIsModalDeleteAccoutant(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <strong>Deseja excluir este contador da empresa?</strong>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => handleOnDeleteAccoutant()}>
            Sim, Excluir
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsModalDeleteAccoutant(false)}
          >
            Não
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de deleção de registro */}
      <Modal
        show={showModalSuccess}
        onHide={() => {
          setShowModalSuccess(false);
          window.location.href = "/clientes";
        }}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
            Sucesso
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>{messageSuccess}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalSuccess(!showModalSuccess)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        aria-labelledby="contained-modal-warning"
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <strong>
            Esta ação não pode ser revertida, deseja cancelar esta conta ?
          </strong>

          <TextField
            label="Motivo do Cancelamento"
            margin="normal"
            variant="outlined"
            size="small"
            value={reasonCancellation}
            onChange={(e) => {
              setReasonCancellation(e.target.value);
              setReasonCancellationError(e.target.value ? false : true);
            }}
            error={reasonCancellationError}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => handleOnDelete()}>
            Sim, Cancelar
          </Button>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Não
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        aria-labelledby="contained-modal-warning"
        show={isModalExpenseOpen}
        onHide={() => setIsModalExpenseOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <strong>
            Esta ação não pode ser revertida, deseja excluir esta conta ?
          </strong>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => handleOnDeleteExpense()}>
            Sim, Excluir
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsModalExpenseOpen(false)}
          >
            Não
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        aria-labelledby="contained-modal-warning"
        show={modalEmitNF || modalEmitNFce}
        onHide={() => {
          setModalEmitNF(false);
          setModalEmitNFce(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <strong>
            Deseja criar
            {typeOrder == "service"
              ? " NFS-e "
              : typeOrder == "purchase" || typeOrder == "request"
              ? modalEmitNFce
                ? " NFc-e "
                : " NF-e "
              : ""}
            a partir {typeOrder == "request" ? "deste" : "desta"}
            {typeOrder == "service"
              ? " ordem de serviço"
              : typeOrder == "purchase"
              ? " ordem de compra"
              : typeOrder == "request"
              ? " pedido"
              : ""}
            ?
          </strong>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => (modalEmitNFce ? redirectNFce() : redirectNF())}
          >
            Criar
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setModalEmitNFce(false);
              setModalEmitNF(false);
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de estorno conta a pagar ou a receber */}
      {billsToPay || billsToReceive ? (
        <Modal
          centered
          aria-labelledby="contained-modal-warning"
          show={isReversalModalOpen}
          onHide={() => setIsReversalModalOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
              Atenção
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <strong>Tem certeza que deseja estornar este registro ?</strong>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="warning" onClick={() => handleOnReversal()}>
              Sim
            </Button>
            <Button
              variant="secondary"
              onClick={() => setIsReversalModalOpen(false)}
            >
              Não
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}

      <Modal
        centered
        aria-labelledby="contained-modal-warning"
        show={modalStockOrder}
        onHide={() => setModalStockOrder(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            Lançar no estoque
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isLoadingStockModal && (
            <div className="d-flex justify-content-center">
              <Spinner
                as="span"
                variant="primary"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}

          {!isLoadingStockModal && (
            <div className="row">
              <div className="col-lg-6">
                <ApiResourceSelect
                  label="Local"
                  getOptionLabel={(option: StockLocations) => option.name}
                  value={stockLocationMove}
                  onSelect={(option) =>
                    setStockLocationMove(String(option?.id ?? ""))
                  }
                  apiSearchHandler={(typedText) =>
                    StockLocationService.getStockLocationsFiltered({
                      name: typedText,
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!stockLocationMove) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(stockLocationMove)
                      ) ??
                      StockLocationService.getStockLocationById(
                        stockLocationMove
                      )
                    );
                  }}
                  disabled={stockLocationMoveIsFromConsignment}
                />
              </div>

              <div className="col-lg-6">
                <TextField
                  label="Observação"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={observationMove}
                  onChange={(e) => setObservationMove(e.target.value)}
                />
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleLaunchInStock}>
            {isSubmittingStockModal ? (
              <>
                <Spinner as="span" animation="border" size="sm" />
                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <>
                <span>Salvar</span>
              </>
            )}
          </Button>
          <Button variant="secondary" onClick={() => setModalStockOrder(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        aria-labelledby="contained-modal-warning"
        show={modalStockReverse}
        onHide={() => setModalStockReverse(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            Estornar do estoque
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isLoadingStockModal && (
            <div className="d-flex justify-content-center">
              <Spinner
                as="span"
                variant="primary"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}

          {!isLoadingStockModal && (
            <div className="row">
              <div className="col-lg-6">
                <ApiResourceSelect
                  label="Local"
                  disabled
                  getOptionLabel={(option: StockLocations) => option.name}
                  value={stockLocationReverse}
                  onSelect={(option) =>
                    setStockLocationReverse(String(option?.id ?? ""))
                  }
                  apiSearchHandler={(typedText) =>
                    StockLocationService.getStockLocationsFiltered({
                      name: typedText,
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!stockLocationReverse) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(stockLocationReverse)
                      ) ??
                      StockLocationService.getStockLocationById(
                        stockLocationReverse
                      )
                    );
                  }}
                />
              </div>

              <div className="col-lg-6">
                <TextField
                  label="Observação"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={observationReverse}
                  onChange={(e) => setObservationReverse(e.target.value)}
                />
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => reverseStock()}>
            {isSubmittingStockModal ? (
              <>
                <Spinner as="span" animation="border" size="sm" />
                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <>
                <span>Salvar</span>
              </>
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => setModalStockReverse(false)}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de ordem */}
      <Modal
        centered
        aria-labelledby="contained-modal-warning"
        size="lg"
        show={isOrdersModalOpen}
        onHide={() => setIsOrdersModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            Situação
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4">
                <TextField
                  label="Data da alteração"
                  type="date"
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={paymentDate}
                />
              </div>

              <div className="col-lg-8">
                <TextField
                  label="Observações"
                  variant="outlined"
                  margin="normal"
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <TextField
                  label="Status"
                  select
                  variant="outlined"
                  margin="normal"
                  value={status}
                  onChange={(evt) => setStatus(evt.target.value)}
                >
                  <MenuItem key="0" value="">
                    Selecione
                  </MenuItem>
                  {hasStock
                    ? OSSituationItens.map((item, index) => (
                        <MenuItem key={index + 1} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))
                    : SituationItens.map((item, index) => (
                        <MenuItem key={index + 1} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                </TextField>
              </div>
            </div>

            <div className="row">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Observação</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>{renderOrderHistory()}</TableBody>
              </Table>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleChangeOrder()}>
            Salvar
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsOrdersModalOpen(false)}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de situação */}
      <ModalChangeBillSituation
        isSituationModalOpen={isSituationModalOpen}
        handleCloseModal={handleCloseBillSituationModal}
        viewOnly={viewOnly}
        situationModalTitle={situationModalTitle ?? ""}
        billsToPay={billsToPay}
        name={name}
        status={status}
        supplier={supplier}
        dueDate={dueDate}
        amount={amount}
        remaining={remaining}
        paymentDate={paymentDate}
        setPaymentDate={setPaymentDate}
        payment={payment}
        setPayment={setPayment}
        bankAccount={bankAccount}
        recordType={recordType}
        handleChangeBankAccount={(bankAccount) =>
          setBankAccount(String(bankAccount?.id ?? ""))
        }
        valueToPay={valueToPay}
        setValueToPay={setValueToPay}
        fee={fee}
        setFee={setFee}
        discount={discount}
        setDiscount={setDiscount}
        tax={tax}
        setTax={setTax}
        acc={acc}
        setAcc={setAcc}
        handleChangeSituation={handleChangeSituation}
        total={total}
        setTotal={setTotal}
        writeOffValue={writeOffValue}
        partialPayments={partialPayments}
        isPaid={isPaid}
        setIsPaid={setIsPaid}
      />

      {/* Modal de lançar em contas */}
      <ModalThrowToBills
        isThrowToBillsModalOpen={isThrowToBillsModalOpen}
        setIsThrowToBillsModalOpen={setIsThrowToBillsModalOpen}
        dreCategoryType={typeOrder === "purchase" ? "expense" : "revenue"}
        dreSubCategoryId={dreSubCategoryId}
        setDreSubCategoryId={setDreSubCategoryId}
        dreSubCategoryIdCommission={dreSubCategoryIdCommission}
        setDreSubCategoryIdCommission={setDreSubCategoryIdCommission}
        setCenterCost={setCenterCost}
        setCenterCostCommission={setCenterCostCommission}
        installments={installments}
        installmentsCommission={installmentsCommission}
        onThrowToBills={handleOnThrowToBills}
        onSetInstallmentBankAccount={handleSetInstallmentBankAccount}
        onSetInstallmentPaymentDate={handleSetInstallmentPaymentDate}
        onSetIsPaid={handleSetIsPaid}
        onSetInstallmentBankAccountCommission={
          handleSetInstallmentBankAccountCommission
        }
        onSetInstallmentPaymentMethodCommission={
          handleSetInstallmentPaymentMethodCommission
        }
        onSetInstallmentPaymentDateCommission={
          handleSetInstallmentPaymentDateCommission
        }
        onSetIsPaidCommission={handleSetIsPaidCommission}
      />

      <ModalSendEmail
        showModal={modalEmailOrder}
        setShowModal={setModalEmailOrder}
        onClickSend={sendEmail}
        emailsHistory={emails}
        infoEmail={infoEmail}
        setInfoEmail={setInfoEmail}
      />

      <Modal
        centered
        aria-labelledby="contained-modal-warning"
        size="lg"
        show={modalWhatsappOrder}
        onHide={() => {
          setModalWhatsappOrder(false);
          setMessage("");
          setWhatsapp([]);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            Enviar por Whatsapp
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <TextField
                size="small"
                multiline
                rows="2"
                margin="normal"
                variant="outlined"
                label="Mensagem"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-3">
            <h3 className="border-bottom mt-3 pb-3">Whatsapp enviados</h3>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Número</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Mensagem</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {whatsapp.length ? (
                  whatsapp
                    .slice(
                      pagesWhatsapp * rowsPerPageWhatsapp,
                      pagesWhatsapp * rowsPerPageWhatsapp + rowsPerPageWhatsapp
                    )
                    .map((value, index) => {
                      var created_at = new Date(value.created_at);
                      var date =
                        created_at.getDate() < 10
                          ? "0" + created_at.getDate()
                          : created_at.getDate();
                      var month =
                        created_at.getMonth() + 1 < 10
                          ? "0" + (created_at.getMonth() + 1)
                          : created_at.getMonth() + 1;

                      var hour =
                        created_at.getHours() + 2 < 10
                          ? "0" + (created_at.getHours() + 2)
                          : created_at.getHours() + 2;

                      return (
                        <TableRow key={index}>
                          <TableCell>{value.number}</TableCell>
                          <TableCell>
                            {date +
                              "/" +
                              month +
                              "/" +
                              created_at.getFullYear() +
                              "  " +
                              hour +
                              ":" +
                              (created_at.getMinutes() < 10
                                ? "0" + created_at.getMinutes()
                                : created_at.getMinutes())}
                          </TableCell>
                          <TableCell>{value.message}</TableCell>
                          <TableCell>
                            {value.status === whatsappStatus.FAILED && (
                              <ValidationBadge
                                variant="danger"
                                label="Falha"
                                title="Falha ao enviar"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell
                      className="text-center"
                      style={{ color: "#ccc" }}
                      colSpan={3}
                    >
                      Nenhum whatsapp enviado até o momento...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <TablePagination
              labelRowsPerPage="Linhas por página"
              page={pagesWhatsapp}
              component="div"
              count={whatsapp.length}
              rowsPerPage={rowsPerPageWhatsapp}
              rowsPerPageOptions={[5, 10, 25]}
              backIconButtonProps={{
                "aria-label": "Página Anterior",
              }}
              nextIconButtonProps={{
                "aria-label": "Próxima Página",
              }}
              onChangePage={(_, next) => handleChangePageWhatsapp(next)}
              onChangeRowsPerPage={(evt) =>
                handleRowsPerPageWhatsapp(Number(evt.target.value))
              }
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => sendWhatsapp()}>
            {sending ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />

                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <span>Enviar</span>
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setModalWhatsappOrder(false);
              setMessage("");
              setWhatsapp([]);
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        aria-labelledby="contained-modal-warning"
        size="lg"
        show={modalWhatsappBill}
        onHide={() => {
          setModalWhatsappBill(false);
          setMessage("");
          setWhatsapp([]);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            Enviar por Whatsapp
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <TextField
                size="small"
                multiline
                rows="2"
                margin="normal"
                variant="outlined"
                label="Mensagem"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-3">
            <h3 className="border-bottom mt-3 pb-3">Whatsapp enviados</h3>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Número</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Mensagem</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {whatsapp.length ? (
                  whatsapp
                    .slice(
                      pagesWhatsapp * rowsPerPageWhatsapp,
                      pagesWhatsapp * rowsPerPageWhatsapp + rowsPerPageWhatsapp
                    )
                    .map((value, i) => {
                      var created_at = new Date(value.created_at);
                      var date =
                        created_at.getDate() < 10
                          ? "0" + created_at.getDate()
                          : created_at.getDate();
                      var month =
                        created_at.getMonth() + 1 < 10
                          ? "0" + (created_at.getMonth() + 1)
                          : created_at.getMonth() + 1;

                      var hour =
                        created_at.getHours() + 2 < 10
                          ? "0" + (created_at.getHours() + 2)
                          : created_at.getHours() + 2;

                      return (
                        <TableRow key={i}>
                          <TableCell>{value.number}</TableCell>
                          <TableCell>
                            {date +
                              "/" +
                              month +
                              "/" +
                              created_at.getFullYear() +
                              "  " +
                              hour +
                              ":" +
                              (created_at.getMinutes() < 10
                                ? "0" + created_at.getMinutes()
                                : created_at.getMinutes())}
                          </TableCell>
                          <TableCell>{value.message}</TableCell>
                          <TableCell>
                            {value.status === whatsappStatus.FAILED && (
                              <ValidationBadge
                                variant="danger"
                                label="Falha"
                                title="Falha ao enviar"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell
                      className="text-center"
                      style={{ color: "#ccc" }}
                      colSpan={3}
                    >
                      Nenhum whatsapp enviado até o momento...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <TablePagination
              labelRowsPerPage="Linhas por página"
              page={pagesWhatsapp}
              component="div"
              count={whatsapp.length}
              rowsPerPage={rowsPerPageWhatsapp}
              rowsPerPageOptions={[5, 10, 25]}
              backIconButtonProps={{
                "aria-label": "Página Anterior",
              }}
              nextIconButtonProps={{
                "aria-label": "Próxima Página",
              }}
              onChangePage={(_, next) => handleChangePageWhatsapp(next)}
              onChangeRowsPerPage={(evt) =>
                handleRowsPerPageWhatsapp(Number(evt.target.value))
              }
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => sendWhatsappBill()}>
            {sending ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />

                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <span>Enviar</span>
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setModalWhatsappBill(false);
              setMessage("");
              setWhatsapp([]);
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <TableContainer style={{ maxHeight: "75vh" }}>
        <Table stickyHeader className="wrap">
          <TableHead>
            <TableRow innerRef={tableRowRef}>
              {headData.map((head, index) => (
                <React.Fragment key={index}>
                  <TableCell key={index} padding="default">
                    {sortable && !head.notSortable ? (
                      <TableSortLabel
                        active={head.reference === sortReference}
                        direction={
                          sortDirection.toLowerCase() as "asc" | "desc"
                        }
                        onClick={() =>
                          handleSort(
                            head.reference,
                            head.reference === sortReference
                          )
                        }
                      >
                        {head.value}
                      </TableSortLabel>
                    ) : (
                      <strong>{head.value}</strong>
                    )}
                  </TableCell>

                  {!!selectCheckbox &&
                    head.reference === (selectCheckbox.reference ?? "id") && (
                      <TableCell padding="default">
                        {selectCheckbox.onChangeSelectAll && (
                          <Tooltip
                            TransitionComponent={Zoom}
                            title="Selecionar Todos"
                          >
                            <Checkbox
                              color="primary"
                              onChange={(e, checked) =>
                                handleChangeSelectAllCheckbox(checked)
                              }
                              checked={checkedConditionSelectAllCheckbox()}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                    )}
                </React.Fragment>
              ))}

              <TableCell padding="default"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoadingData && (
              <TableRow>
                <TableCell colSpan={99}>
                  <div className="d-flex justify-content-center">
                    <Spinner
                      as="span"
                      variant="primary"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!isLoadingData &&
              bodyData
                .slice(
                  isBackEndPagination ? 0 : pages * rowsPerPage,
                  isBackEndPagination
                    ? bodyData.length
                    : pages * rowsPerPage + rowsPerPage
                )
                .map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow hover>
                      {row.map((cell, i) => {
                        if (cell.hidden || cell.secondaryRow) {
                          return null;
                        }

                        if (cell.jsx) {
                          return <TableCell key={i}>{cell.jsx}</TableCell>;
                        }

                        if (
                          (situation || order || invoice) &&
                          cell.for != "kit" &&
                          cell.for != "type" &&
                          cell.for != "isAccountant"
                        ) {
                          if (i === indexSituation) {
                            const v = getVariant(cell.value);
                            const customStatusLabel = row.find(
                              (rowData) => rowData.for == "customStatusLabel"
                            )?.value;
                            const customStatusColor: any = row.find(
                              (rowData) => rowData.for == "customStatusColor"
                            )?.value;

                            return (
                              <TableCell key={i}>
                                {customStatusLabel ? (
                                  <Badge
                                    variant={customStatusColor}
                                    style={
                                      customStatusColor == "success"
                                        ? colorSuccess
                                        : customStatusColor === "primary"
                                        ? colorBlue
                                        : {}
                                    }
                                  >
                                    {customStatusLabel}
                                  </Badge>
                                ) : (
                                  <>
                                    {cell.value && cell.value !== "custom" ? (
                                      <Badge
                                        variant={v}
                                        style={
                                          v == "success"
                                            ? colorSuccess
                                            : v === "primary"
                                            ? colorBlue
                                            : {}
                                        }
                                      >
                                        {cell.value}
                                      </Badge>
                                    ) : (
                                      <Badge
                                        className="cursor-pointer"
                                        variant="danger"
                                      >
                                        Status Excluido
                                      </Badge>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            );
                          }
                        }

                        if (
                          cell.for != "kit" &&
                          cell.for != "type" &&
                          cell.for != "customStatusLabel" &&
                          cell.for != "customStatusColor" &&
                          cell.for != "isAccountant" &&
                          !cell.secondaryRow
                        ) {
                          return (
                            <React.Fragment key={i}>
                              <TableCell key={"columnRow" + i}>
                                {products && i == 3 ? (
                                  <>{cell.value}</>
                                ) : (
                                  <>
                                    {cell.for != "movedToStock" ? (
                                      cell.value == "SALDO ANTERIOR" ? (
                                        <b>{cell.value}</b>
                                      ) : (
                                        <span title={cell.value}>
                                          {cell.limitText !== false
                                            ? limitString(cell.value, 40)
                                            : cell.value}
                                        </span>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </TableCell>

                              {!!selectCheckbox &&
                                cell.for ===
                                  (selectCheckbox.reference ?? "id") &&
                                user.isAccountant == "n" && (
                                  <TableCell key="checkbox">
                                    {canShowSelectCheckbox(row) && (
                                      <Checkbox
                                        color="primary"
                                        onChange={(e, checked) =>
                                          handleChangeSelectCheckbox(
                                            checked,
                                            row
                                          )
                                        }
                                        checked={checkedConditionSelectCheckbox(
                                          row
                                        )}
                                      />
                                    )}
                                  </TableCell>
                                )}
                            </React.Fragment>
                          );
                        }
                      })}

                      {lastCell ? (
                        <>
                          <TableCell
                            key="lastCell"
                            align="left"
                            style={{ maxWidth: "80px" }}
                          >
                            {!actionsButtonShowCondition ||
                            actionsButtonShowCondition(
                              row.find((r) => r.id)?.value ?? ""
                            ) ? (
                              <div title="Mostrar ações">
                                <DropdownButton
                                  className="btn-light-primary dropdown-toggle"
                                  id="dropdown-basic-button"
                                  type="button"
                                  title={
                                    <i className="ki ki-bold-more-ver p-0"></i>
                                  }
                                >
                                  {customButtons && customButtons.length ? (
                                    <>
                                      {customButtons.map((btn, index) => {
                                        if (
                                          btn.showCondition &&
                                          btn.showCondition(
                                            row.find((r) => r.id)?.value ?? ""
                                          ) === false
                                        ) {
                                          return null;
                                        }
                                        return (
                                          <Tooltip
                                            key={index}
                                            TransitionComponent={Zoom}
                                            title={btn.popup}
                                          >
                                            <Button
                                              className={`p-2 mr-3 ${btn.class}`}
                                              variant={btn.variant ?? "primary"}
                                              type="button"
                                              onClick={() =>
                                                btn.onClick
                                                  ? btn.onClick(
                                                      row.find((r) => r.id)
                                                        ?.value ?? ""
                                                    )
                                                  : null
                                              }
                                            >
                                              {btn.content}
                                            </Button>
                                          </Tooltip>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {hasStock && user.isAccountant == "n" && (
                                    <>
                                      {getBillCreatedCellValue(row) == "y" &&
                                        getMovedToStockCellValue(row) !==
                                          "false" && (
                                          <>
                                            {getMovedToStockCellValue(row) ==
                                            "y" ? (
                                              <Tooltip
                                                TransitionComponent={Zoom}
                                                title="Estornar do estoque"
                                              >
                                                <Button
                                                  className="btn-light-secondary p-2 mr-3"
                                                  type="button"
                                                  onClick={() => {
                                                    setModalStockReverse(true);
                                                    getStockMoved(row[0].value);
                                                    setIndexSelected(index);
                                                  }}
                                                >
                                                  <i className="flaticon2-cross p-0"></i>
                                                </Button>
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                TransitionComponent={Zoom}
                                                title="Lançar no estoque"
                                              >
                                                <Button
                                                  className="btn-light-secondary p-2 mr-3"
                                                  type="button"
                                                  onClick={() =>
                                                    handleOpenLaunchInStockModal(
                                                      Number(row[0].value)
                                                    )
                                                  }
                                                >
                                                  <i className="flaticon2-box-1 p-0"></i>
                                                </Button>
                                              </Tooltip>
                                            )}
                                          </>
                                        )}
                                    </>
                                  )}

                                  {showInvoiceButton(
                                    row[invoiceColumn]?.value
                                  ) &&
                                  (typeOrder == "service" ||
                                    typeOrder == "purchase" ||
                                    typeOrder == "request") &&
                                  user.isAccountant == "n" ? (
                                    <>
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Criar nota fiscal a partir deste registro"
                                      >
                                        <Button
                                          className="btn p-2 mr-3"
                                          type="button"
                                          style={btnWarning}
                                          onClick={() =>
                                            handleClickEmitNf(
                                              Number(row[0].value)
                                            )
                                          }
                                        >
                                          <i className="flaticon2-paper p-0"></i>
                                        </Button>
                                      </Tooltip>
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Criar NFce a partir deste registro"
                                      >
                                        <Button
                                          className="btn p-2 mr-3"
                                          type="button"
                                          style={btnNfce}
                                          onClick={() =>
                                            handleClickEmitNfce(
                                              Number(row[0].value)
                                            )
                                          }
                                        >
                                          <i className="flaticon2-paper p-0"></i>
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {order && user.isAccountant == "n" ? (
                                    <>
                                      <PDFDownloadLink
                                        document={
                                          <PdfOrder
                                            orderId={row[0].value}
                                            typeOrder={typeOrder ?? ""}
                                            isOptics={
                                              selectedCompany?.businessCategory ===
                                              BusinessCategory.OPTICS
                                            }
                                          />
                                        }
                                        fileName={
                                          typeOrder == "request"
                                            ? "pedido_" + row[0].value
                                            : typeOrder == "budget"
                                            ? "orcamento_" + row[0].value
                                            : typeOrder == "purchase"
                                            ? "ordem_de_compra_" + row[0].value
                                            : typeOrder == "service"
                                            ? "ordem_de_servico_" + row[0].value
                                            : "ordem_" + row[0].value
                                        }
                                      >
                                        <Tooltip
                                          TransitionComponent={Zoom}
                                          title="Baixar PDF"
                                        >
                                          <Button
                                            className="btn-light-secondary p-2 mr-3"
                                            type="button"
                                          >
                                            <i className="flaticon2-printer p-0"></i>
                                          </Button>
                                        </Tooltip>
                                      </PDFDownloadLink>
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Envio de whatsapp"
                                      >
                                        <Button
                                          className="btn p-2 mr-3"
                                          type="button"
                                          style={{
                                            background: "#50cd89",
                                            border: "none",
                                          }}
                                          onClick={() =>
                                            handleOpenSendWhatsappModal(
                                              Number(row[0].value),
                                              "order"
                                            )
                                          }
                                        >
                                          <img
                                            src="/media/icons/whatsapp.png"
                                            alt="Whatsapp"
                                          />
                                        </Button>
                                      </Tooltip>
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Envio de email"
                                      >
                                        <Button
                                          className="btn p-2 mr-3"
                                          type="button"
                                          style={{
                                            background: "#6993ff",
                                            border: "none",
                                          }}
                                          onClick={() =>
                                            handleOpenSendEmailModal(
                                              Number(row[0].value),
                                              "order"
                                            )
                                          }
                                        >
                                          <i
                                            className="flaticon2-email p-0"
                                            style={{ color: "#fff" }}
                                          ></i>
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {onClone && user.isAccountant == "n" && (
                                    <Tooltip
                                      TransitionComponent={Zoom}
                                      title="Duplicar"
                                    >
                                      <Button
                                        className="btn-light-info p-2 mr-3"
                                        variant="info"
                                        type="button"
                                        onClick={() => onClone(row[0].value)}
                                      >
                                        <i className="flaticon2-copy p-0"></i>
                                      </Button>
                                    </Tooltip>
                                  )}

                                  {(onEdit && !budget) ||
                                  (budget &&
                                    row[4].value != "Lançado em Pedidos") ||
                                  ((billsToPay || billsToReceive) &&
                                    row[6].value !== "Pago") ? (
                                    row.find((r) => r.for === "editButton")
                                      ?.value !== "n" && (
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Editar"
                                      >
                                        <Button
                                          className="btn-light-primary p-2 mr-3"
                                          variant="primary"
                                          type="button"
                                          onClick={() => handleClickEdit(row)}
                                        >
                                          <i className="flaticon2-edit p-0"></i>
                                        </Button>
                                      </Tooltip>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                  {onSendPromotion ? (
                                    row.find(
                                      (r) => r.for === "sendPromotionButton"
                                    )?.value !== "n" && (
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Enviar Promoção"
                                      >
                                        <Button
                                          className="btn-light-primary p-2 mr-3"
                                          variant="primary"
                                          type="button"
                                          onClick={() =>
                                            handleClickSendPromotion(row)
                                          }
                                        >
                                          <i className="flaticon-whatsapp p-0"></i>
                                        </Button>
                                      </Tooltip>
                                    )
                                  ) : (
                                    <></>
                                  )}

                                  {onEditPromotion ? (
                                    row.find((r) => r.for === "editButton")
                                      ?.value !== "n" && (
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Editar"
                                      >
                                        <Button
                                          className="btn-light-primary p-2 mr-3"
                                          variant="primary"
                                          type="button"
                                          onClick={() =>
                                            handleClickEditPromotion(row)
                                          }
                                        >
                                          <i className="flaticon2-edit p-0"></i>
                                        </Button>
                                      </Tooltip>
                                    )
                                  ) : (
                                    <></>
                                  )}

                                  {onDeletePromotion ? (
                                    row.find((r) => r.for === "deleteButton")
                                      ?.value !== "n" && (
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Excluir"
                                      >
                                        <Button
                                          className="btn-light-primary p-2 mr-3"
                                          variant="primary"
                                          type="button"
                                          onClick={() =>
                                            handleClickDeletePromotion(row)
                                          }
                                        >
                                          <i className="flaticon-delete p-0"></i>
                                        </Button>
                                      </Tooltip>
                                    )
                                  ) : (
                                    <></>
                                  )}

                                  {onPromotion ? (
                                    row.find((r) => r.for === "promotionButton")
                                      ?.value !== "n" && (
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Promoções"
                                      >
                                        <Button
                                          className="btn-light-primary p-2 mr-3"
                                          variant="primary"
                                          type="button"
                                          onClick={() =>
                                            handleClickPromotion(row)
                                          }
                                        >
                                          <i className="flaticon2-percentage p-0"></i>
                                        </Button>
                                      </Tooltip>
                                    )
                                  ) : (
                                    <></>
                                  )}

                                  {(billsToPay || billsToReceive) &&
                                  row[6].value != "Cancelado" ? (
                                    <>
                                      {billsToReceive ? (
                                        <>
                                          {user.isAccountant == "n" ? (
                                            <>
                                              <Tooltip
                                                TransitionComponent={Zoom}
                                                title="Envio de whatsapp"
                                              >
                                                <Button
                                                  className="btn p-2 mr-3"
                                                  type="button"
                                                  style={{
                                                    background: "#50cd89",
                                                    border: "none",
                                                  }}
                                                  onClick={() =>
                                                    handleOpenSendWhatsappModal(
                                                      Number(row[0].value),
                                                      "bill"
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src="/media/icons/whatsapp.png"
                                                    alt="Whatsapp"
                                                  />
                                                </Button>
                                              </Tooltip>
                                              <Tooltip
                                                TransitionComponent={Zoom}
                                                title="Envio de email"
                                              >
                                                <Button
                                                  className="btn p-2 mr-3"
                                                  type="button"
                                                  style={{
                                                    background: "#6993ff",
                                                    border: "none",
                                                  }}
                                                  onClick={() =>
                                                    handleOpenSendEmailModal(
                                                      Number(row[0].value),
                                                      "bill"
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="flaticon2-email p-0"
                                                    style={{ color: "#fff" }}
                                                  ></i>
                                                </Button>
                                              </Tooltip>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {row[7].value == "billet" &&
                                          row[5].value == "Boleto" &&
                                          row[6].value != "Pago" ? (
                                            <Tooltip
                                              TransitionComponent={Zoom}
                                              title="Visualizar boleto"
                                            >
                                              <Button
                                                className="btn-light-warning p-2 mr-3"
                                                type="button"
                                                onClick={() => {
                                                  getBillet(
                                                    Number(row[0].value)
                                                  );
                                                }}
                                              >
                                                <i className="flaticon2-sheet p-0"></i>
                                              </Button>
                                            </Tooltip>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      {(billsToPay || billsToReceive) &&
                                      (row[6].value == "Pendente" ||
                                        row[6].value == "Vencendo" ||
                                        row[6].value == "Vencido" ||
                                        row[6].value == "Atrasado") ? (
                                        <Tooltip
                                          TransitionComponent={Zoom}
                                          title="Liquidar conta"
                                        >
                                          <Button
                                            className="btn p-2 mr-3"
                                            style={btnSuccess}
                                            type="button"
                                            onClick={() =>
                                              handleSetSituationModal(
                                                index,
                                                row[0].value
                                              )
                                            }
                                          >
                                            <i className="flaticon-coins p-0"></i>
                                          </Button>
                                        </Tooltip>
                                      ) : (billsToPay || billsToReceive) &&
                                        row[6].value == "Pago" ? (
                                        <>
                                          <Tooltip
                                            TransitionComponent={Zoom}
                                            title="Visualizar conta"
                                          >
                                            <Button
                                              className="btn p-2 mr-3"
                                              style={btnSuccess}
                                              type="button"
                                              onClick={() =>
                                                handleClickEdit(row)
                                              }
                                              // onClick={() =>
                                              //   handleSetSituationModal(
                                              //     index,
                                              //     row[0].value
                                              //   )
                                              // }
                                            >
                                              <i className="flaticon-search p-0"></i>
                                            </Button>
                                          </Tooltip>

                                          {row[7].value != "transfer" &&
                                          user.isAccountant == "n" ? (
                                            <Tooltip
                                              TransitionComponent={Zoom}
                                              title="Estornar"
                                            >
                                              <Button
                                                className="btn p-2 mr-3"
                                                style={btnWarning}
                                                type="button"
                                                onClick={() =>
                                                  handleReversalModal(
                                                    index,
                                                    row[0].value
                                                  )
                                                }
                                              >
                                                <i className="flaticon2-arrow-2 p-0"></i>
                                              </Button>
                                            </Tooltip>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      {(billsToPay || billsToReceive) &&
                                      row[6].value != "Cancelado" &&
                                      user.isAccountant == "n" ? (
                                        <Tooltip
                                          TransitionComponent={Zoom}
                                          title="Cancelar Conta"
                                        >
                                          <Button
                                            className="btn-danger p-2 mr-3"
                                            variant="danger"
                                            type="button"
                                            onClick={() =>
                                              handleClickDelete(row)
                                            }
                                          >
                                            <i className="flaticon-delete p-0"></i>
                                          </Button>
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {products &&
                                  row[5].value == "n" &&
                                  user.isAccountant == "n" ? (
                                    <Tooltip
                                      TransitionComponent={Zoom}
                                      title="Estoque"
                                    >
                                      <Button
                                        className="btn-light-secondary p-2 mr-3"
                                        type="button"
                                        onClick={() => handleClickStock(row)}
                                      >
                                        <i className="flaticon2-box-1 p-0"></i>
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    <></>
                                  )}

                                  {recurringExpenses &&
                                  user.isAccountant == "n" ? (
                                    <>
                                      <></>
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Cancelar Conta"
                                      >
                                        <Button
                                          className="btn-danger p-2 mr-3"
                                          variant="danger"
                                          type="button"
                                          onClick={() =>
                                            handleClickExpenseDelete(row)
                                          }
                                        >
                                          <i className="flaticon-delete p-0"></i>
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {onDeleteAccountant &&
                                  row[3].for == "isAccountant" &&
                                  row[3].value == "y" ? (
                                    <Button
                                      className="btn-light-danger p-2 mr-3"
                                      variant="danger"
                                      type="button"
                                      onClick={() => {
                                        setIndexToDelete(row[0].value);
                                        setIsModalDeleteAccoutant(true);
                                      }}
                                    >
                                      <i className="flaticon-delete p-0"></i>
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </DropdownButton>
                              </div>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                    {(() => {
                      const secondaryRow = row.find(
                        (cell) => cell.secondaryRow
                      );
                      if (!secondaryRow) return null;

                      return (
                        <TableRow>
                          <TableCell colSpan={99}>
                            {secondaryRow.jsx ?? secondaryRow.value}
                          </TableCell>
                        </TableRow>
                      );
                    })()}
                  </React.Fragment>
                ))}

            {!isLoadingData && bodyData.length === 0 && (
              <TableRow>
                <TableCell colSpan={99} className="text-center">
                  Nenhum resultado encontrado
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage="Linhas por página"
        page={pages}
        component="div"
        count={totalCount ?? bodyData.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        backIconButtonProps={{
          "aria-label": "Página Anterior",
        }}
        nextIconButtonProps={{
          "aria-label": "Próxima Página",
        }}
        onChangePage={(_, next) => handleChangePage(next)}
        onChangeRowsPerPage={(evt) =>
          handleRowsPerPage(Number(evt.target.value))
        }
      />
    </Paper>
  );
};
