import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Link,
} from "@material-ui/core";
import { Button, Tab, Tabs, Spinner } from "react-bootstrap";

import api from "../../services/Api";
import { useSubheader } from "../../../_metronic/layout";
import { paymentOptions } from "../../utils/paymentOptions";
import { NumericFormat } from "../../components/NumericFormat";
import ModalError from "../../components/ModalError";

import "../../style.css";
import {
  dateIsBetweenRange,
  extractDateStringFromTimestamp,
} from "../../utils/dateTimeHelper";
import LogService from "../../services/LogService";
import { SubCategory } from "../../types/Dre";
import { BillsToReceive, billetType } from "../../types/BillsToReceive";
import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import ApiResourceSelect from "../../components/ApiResourceSelect";
import CustomerService from "../../services/CustomerService";
import BankAccountService from "../../services/BankAccountService";
import { BankAccount } from "../../types/BankAccount";
import DreCategoryService from "../../services/DreCategoryService";
import { CenterCost } from "../../types/CenterCost";
import CenterCostService from "../../services/CenterCostService";
import BillToReceiveService from "../../services/BillToReceiveService";
import { ApiResourceSelectAddButton } from "../../components/ApiResourceSelectAddButton";
import useAddAccountBank from "../../hooks/addAccountBank";
import { AddAccountBankModal } from "../../components/AccountBank/AddAccountBankModal";
import { NewCustomerModal } from "../../components/Customer/NewCustomerModal";
import useNewCustomerForm from "../../hooks/newCustomerForm";
import { Customer } from "../../types/Customer";
import ModalSuccess from "../../components/ModalSuccess";
import { bankNumberIsInter } from "../../utils/bankNumberIsInter";
import { useStyles } from "../../hooks/styles";
import { uploadMultipleFiles } from "../../utils/FilesHelper";
import UploadFiles from "../../components/UploadFiles";

export function NewBillsToReceive() {
  const [activeTab, setActiveTab] = useState("detalhes-receita");

  const [isSubmit, setIsSubmit] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [dreSubCategoryId, setDreSubCategoryId] = useState(0);

  const [msgError, setMsgError] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const [isGenerateInstallment, setIsGenerateInstallment] = useState(false);

  const [name, setName] = useState("");
  const [payment, setPayment] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [recordType, setRecordType] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [comments, setComments] = useState("");
  const [occurrence, setOccurrence] = useState("unica");
  const [amount, setAmount] = useState("0");
  const [isInstallment, setIsInstallment] = useState(false);
  const [accountBank, setAccountBank] = useState("");
  const [centerCost, setCenterCost] = useState("");
  const [customer, setCustomer] = useState("");
  const [issuanceDate, setIssuanceDate] = useState("");
  const [selectedBankAccountIsInter, setSelectedBankAccountIsInter] = useState(
    false
  );
  const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
  const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);
  // Inputs Verify
  const [revenueNameError, setRevenueNameError] = useState(false);
  const [accountBankError, setAccountBankError] = useState(false);
  const [accountPlanError, setAccountPlanError] = useState(false);
  const [payMethodsError, setPayMethodsError] = useState(false);
  const [customerError, setCustomerError] = useState(false);
  const [dueError, setDueError] = useState(false);
  const [valueError, setValueError] = useState(false);
  const [issuanceDateError, setIssuanceDateError] = useState(false);

  const [installments, setInstallments] = useState({
    installments: [
      {
        date: "",
        value: 0,
        typePayment: "",
      },
    ],
  });

  const { idToClone } = useParams<{ idToClone: string }>();
  const classes = useStyles();
  const history = useHistory();
  const subHeader = useSubheader();
  const { register, watch, handleSubmit } = useForm();

  // Modal AddAccountBank
  const {
    showModalAddAccountBank,
    setShowModalAddAccountBank,
  } = useAddAccountBank();
  const handleCreateAccountBank = (accountBank: BankAccount) => {
    handleChangeAccountBank(accountBank);
  };

  // Modal New Customer
  const {
    showModalNewCustomer,
    setShowModalNewCustomer,
    newCustomerDefaultData,
    setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setCustomer(String(createdCustomer.id));
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText });
    setShowModalNewCustomer(true);
  };

  useEffect(() => {
    async function getDefaultAccountBank() {
      if (idToClone) {
        return;
      }

      const {
        standardAccountForRecipes,
      } = await BankAccountService.getDefaultBankAccounts();
      handleChangeAccountBank(standardAccountForRecipes);
    }

    getDefaultAccountBank();
    setIssuanceDate(extractDateStringFromTimestamp());
  }, [idToClone]);

  useEffect(() => {
    if (!idToClone) return;

    async function getBillToCloneData() {
      const { data: billToClone } = await api.get<BillsToReceive>(
        `billsToReceive/${idToClone}`
      );

      if (!billToClone) return;

      setName(billToClone.name ?? "");
      setAmount(formatCurrency(billToClone.amount));
      setDueDate(String(billToClone.dueDate ?? ""));
      setPayment(String(billToClone.payment ?? ""));
      setComments(String(billToClone.comments ?? ""));
      setCustomer(billToClone.customer ? String(billToClone.customer) : "");
      setDocNumber(billToClone.docNumber ?? "");
      setCenterCost(String(billToClone.centerCost ?? ""));
      setRecordType(String(billToClone.recordType ?? ""));
      handleChangeAccountBank(billToClone.bankAccountEntity);
      setIssuanceDate(String(billToClone.issuanceDate ?? ""));
      setDreSubCategoryId(billToClone.dreSubCategoryId ?? 0);
    }

    getBillToCloneData();
  }, [idToClone]);

  subHeader.setTitle("Adicionar Conta a Receber");

  // function formatToFloat(value: any) {
  //     var auxValue = value;

  //     if (auxValue) {
  //         if (auxValue.includes('R$')) {
  //             auxValue = auxValue.replace('R$', '');
  //         }

  //         if (auxValue.includes(',') && !auxValue.includes('.')) {
  //            return parseFloat(auxValue.replace(',', '.'));

  //         } else {
  //            return parseFloat(auxValue.replace('.', '').replace(',', '.'));
  //         }
  //     } else {
  //         return auxValue;
  //     }
  // }

  const handleChangeAccountBank = useCallback(
    (accountBank: BankAccount | null | undefined) => {
      setAccountBank(String(accountBank?.id ?? ""));
      setSelectedBankAccountIsInter(
        accountBank ? bankNumberIsInter(accountBank.numberBank) : false
      );
    },
    []
  );

  const handleChangeRecordType = (value: string) => {
    setRecordType(value);

    if (value === "billet") {
      setPayment("Boleto");
    }
  };

  function changeOccurrence(type: any) {
    if (type == "parcelada") {
      setIsInstallment(true);
    } else {
      setIsInstallment(false);
    }
  }

  function convertToFloat(value: any) {
    var valueConverted = value.includes("R$") ? value.replace("R$", "") : value;
    if (valueConverted.includes(".") && valueConverted.includes(",")) {
      valueConverted = valueConverted.replace(".", "").replace(",", ".");
    } else {
      valueConverted = valueConverted.replace(",", ".");
    }

    return parseFloat(valueConverted);
  }

  function generateInstallment() {
    if (occurrence == "parcelada") {
      if (amount && amount != "0") {
        var allInstallments = [];
        var countInstallments = watch("installments");
        var valueExpense = convertToFloat(amount);
        var resultDivided = valueExpense / watch("installments");
        var currentDate = new Date();

        for (var index = 0; index < countInstallments; index++) {
          var month = currentDate.getMonth() + 1;

          allInstallments.push({
            date:
              currentDate.getFullYear() +
              "-" +
              (month < 10 ? "0" + month : month) +
              "-" +
              currentDate.getDate(),
            value: resultDivided,
            typePayment: "Boleto",
          });

          currentDate.setMonth(currentDate.getMonth() + 1);
        }

        setInstallments({ installments: allInstallments });
        setIsGenerateInstallment(true);
      } else {
        setMsgError("Informe o valor da receita para gerar parcelas!");
        setShowModalError(true);
        setInstallments({ installments: [] });
        setIsGenerateInstallment(false);
      }
    } else {
      setInstallments({ installments: [] });
      setIsGenerateInstallment(false);
    }
  }

  function distributeInstallments(value: any, index: any) {
    var allInstallments = installments.installments;

    if (index == 0) {
      for (
        var indexInstallment = 0;
        indexInstallment < allInstallments.length;
        indexInstallment++
      ) {
        if (indexInstallment > index) {
          var distributed =
            Math.abs(value - convertToFloat(amount)) /
            (allInstallments.length - 1);

          allInstallments[indexInstallment].value = distributed;
        }
      }
    } else {
      var totalSumBeforeInstallments = 0;
      var countInstallments = allInstallments.length;

      for (
        var indexInstallment = 0;
        indexInstallment < allInstallments.length;
        indexInstallment++
      ) {
        if (indexInstallment == index) {
          var distributed = Math.abs(
            totalSumBeforeInstallments - convertToFloat(amount)
          );

          if (allInstallments[indexInstallment].value > distributed) {
            allInstallments[indexInstallment].value =
              distributed / countInstallments;
            setMsgError("O valor ultrapassou o limite da soma das parcelas!");
            setShowModalError(true);
          } else {
            totalSumBeforeInstallments +=
              allInstallments[indexInstallment].value;
            countInstallments--;
          }
        }

        if (indexInstallment < index) {
          totalSumBeforeInstallments += allInstallments[indexInstallment].value;
          countInstallments--;
        } else if (indexInstallment > index) {
          var distributed =
            Math.abs(totalSumBeforeInstallments - convertToFloat(amount)) /
            countInstallments;
          allInstallments[indexInstallment].value = distributed;
        }
      }
    }

    setInstallments({ installments: allInstallments });
  }

  const onSubmit = async (dataForm: any) => {
    try {
      setIsSubmit(true);

      const isValid = await inputsVerify(dataForm);

      if (!isValid) return;

      let filesPath: any = [];
      if (filesSelected) {
        filesPath = await uploadMultipleFiles(
          filesSelected,
          setShowModalError,
          setMsgError,
          setIsSubmit
        );
        if (filesPath === false) {
          return;
        }
      }

      const bank = await api.get(`/accountBank/${parseInt(accountBank)}`);
      const today = new Date().toISOString().split("T")[0];

      let billBilletType: billetType | null = null;

      if (recordType === "billet") {
        // Verificando se o banco selecionado está corretamente configurado para emitir boletos
        const bankError = await BankAccountService.validateBilletRequiredInformation(
          bank.data
        );

        if (bankError) {
          setMsgError(bankError);
          setShowModalError(true);
          setIsSubmit(false);
          return;
        }

        if (selectedBankAccountIsInter) {
          billBilletType = billetType.INTER;
        } else {
          billBilletType = billetType.MANUAL;
        }
      }

      if (occurrence == "parcelada") {
        var countInstallments = parseInt(watch("installments"));
        var lastNumberBank = bank.data.billetSequence;

        for (
          var indexSubmit = 0;
          indexSubmit < countInstallments;
          indexSubmit++
        ) {
          const allInstallments = installments.installments;
          const willGenerateBillet =
            recordType === "billet" &&
            allInstallments[indexSubmit].typePayment == "Boleto" &&
            !isPaid;

          lastNumberBank = willGenerateBillet
            ? bank.data.billetSequence + indexSubmit
            : bank.data.billetSequence;

          const data = {
            isPaid,
            name: name + ` ${indexSubmit + 1}/${countInstallments}`,
            amount: allInstallments[indexSubmit].value,
            remaining: allInstallments[indexSubmit].value,
            status: isPaid ? "paid" : "pending",
            bankAccount:
              String(accountBank).length > 0 ? parseInt(accountBank) : null,
            nameBank:
              String(accountBank).length > 0 ? bank.data.nameBank : null,
            categoryName:
              String(dataForm.categoryName).length > 0
                ? dataForm.categoryName
                : null,
            centerCost:
              String(centerCost).length > 0 ? parseInt(centerCost) : null,
            comments: String(comments).length > 0 ? comments : null,
            customer: customer.length > 0 ? Number(customer) : null,
            docNumber: String(docNumber).length > 0 ? docNumber : null,
            dueDate: allInstallments[indexSubmit].date,
            issuanceDate: String(issuanceDate).length > 0 ? issuanceDate : null,
            occurrence: String(occurrence).length > 0 ? occurrence : null,
            payment: allInstallments[indexSubmit].typePayment,
            recordType: String(recordType).length > 0 ? recordType : null,
            billetNumber: willGenerateBillet ? lastNumberBank : undefined,
            billetType: billBilletType,
            dreSubCategoryId: dreSubCategoryId > 0 ? dreSubCategoryId : null,
            payedDate: isPaid ? today : null,
            totalPaid: isPaid ? allInstallments[indexSubmit].value : null,
            attachments: JSON.stringify(filesPath),
          };

          const billResponse = await api.post("billsToReceive", data);
          billResponse.data.bankAccountEntity = bank.data;

          if (willGenerateBillet) {
            await BillToReceiveService.generateBillet(billResponse.data, {
              updateBilletSequence: false,
            });
          }

          LogService.logRegister({
            itemId: billResponse.data.id,
            module: "Contas a Receber",
            itemName: billResponse.data.name,
          });
        }

        if (recordType === "billet") {
          await api.post(`/accountBank/${parseInt(accountBank)}`, {
            billetSequence: lastNumberBank + 1,
          });
        }
      } else {
        const data = {
          isPaid,
          name: name,
          amount: formatToFloat(amount),
          remaining: formatToFloat(amount),
          status: isPaid ? "paid" : "pending",
          bankAccount:
            String(accountBank).length > 0 ? parseInt(accountBank) : null,
          nameBank: String(accountBank).length > 0 ? bank.data.nameBank : null,
          categoryName:
            String(dataForm.categoryName).length > 0
              ? dataForm.categoryName
              : null,
          centerCost:
            String(centerCost).length > 0 ? parseInt(centerCost) : null,
          comments: String(comments).length > 0 ? comments : null,
          customer: customer.length > 0 ? Number(customer) : null,
          docNumber: String(docNumber).length > 0 ? docNumber : null,
          dueDate: String(dueDate).length > 0 ? dueDate : null,
          issuanceDate: String(issuanceDate).length > 0 ? issuanceDate : null,
          occurrence: String(occurrence).length > 0 ? occurrence : null,
          payment: String(payment).length > 0 ? payment : null,
          recordType: String(recordType).length > 0 ? recordType : null,
          billetNumber: bank.data.billetSequence,
          billetType: billBilletType,
          dreSubCategoryId: dreSubCategoryId > 0 ? dreSubCategoryId : null,
          payedDate: isPaid ? today : null,
          totalPaid: isPaid ? formatToFloat(amount) : null,
          attachments: JSON.stringify(filesPath),
        };

        const billResponse = await api.post<BillsToReceive>(
          "billsToReceive",
          data
        );
        billResponse.data.bankAccountEntity = bank.data;

        if (recordType === "billet" && payment === "Boleto" && !isPaid) {
          const billetDocument = await BillToReceiveService.generateBillet(
            billResponse.data
          );
          billResponse.data.billetDocument = billetDocument || "";

          window.open(
            await BillToReceiveService.getBilletFileUrl(billResponse.data),
            "_blank",
            "width=800,height=800"
          );
        }

        LogService.logRegister({
          itemId: billResponse.data.id,
          module: "Contas a Receber",
          itemName: billResponse.data.name,
        });
      }

      setMsgSuccess("Conta a receber criada com sucesso!");
      setShowModalSuccess(true);
      setIsSubmit(false);
    } catch (error) {
      setIsSubmit(false);
      if (error.response?.data?.violacoes) {
        const firstError = error.response.data.violacoes[0];
        setMsgError(`Campo ${firstError.propriedade}: ${firstError.razao}`);
      } else if (error.response?.data?.message) {
        setMsgError(error.response?.data?.message);
      } else {
        setMsgError("Ocorreu um erro ao processar a requisição");
      }
      setShowModalError(true);
    }
  };

  async function inputsVerify(dataForm: any) {
    setRevenueNameError(false);
    setAccountBankError(false);
    setAccountPlanError(false);
    setPayMethodsError(false);
    setCustomerError(false);
    setDueError(false);
    setValueError(false);
    setIssuanceDateError(false);

    if (!name) {
      setActiveTab("detalhes-receita");
      setIsSubmit(false);
      setMsgError("Campo Nome da receita é obrigatório!");
      setShowModalError(true);
      setRevenueNameError(true);
      return false;
    }

    if (!accountBank) {
      setActiveTab("detalhes-receita");
      setIsSubmit(false);
      setMsgError("Selecione uma conta bancária!");
      setShowModalError(true);
      setAccountBankError(true);
      return false;
    }

    if (!payment) {
      setActiveTab("detalhes-receita");
      setIsSubmit(false);
      setMsgError("Campo Forma de Pagamento é obrigatório!");
      setShowModalError(true);
      setPayMethodsError(true);
      return false;
    }

    if (!dreSubCategoryId) {
      setActiveTab("detalhes-receita");
      setIsSubmit(false);
      setMsgError("Campo Plano de Contas é obrigatório!");
      setShowModalError(true);
      setAccountPlanError(true);
      return false;
    }

    if (!customer) {
      setActiveTab("detalhes-receita");
      setIsSubmit(false);
      setMsgError("Campo Cliente é obrigatório!");
      setShowModalError(true);
      setCustomerError(true);
      return false;
    }

    if (!dueDate) {
      setActiveTab("detalhes-receita");
      setIsSubmit(false);
      setMsgError("Campo Vencimento é obrigatório!");
      setShowModalError(true);
      setDueError(true);
      return false;
    }

    if (!amount) {
      setActiveTab("detalhes-receita");
      setIsSubmit(false);
      setMsgError("Campo Valor é obrigatório!");
      setShowModalError(true);
      setValueError(true);
      return false;
    }
    if (Number(amount) <= 0) {
      setActiveTab("detalhes-receita");
      setIsSubmit(false);
      setMsgError("Campo Valor deve ser maior que zero!");
      setShowModalError(true);
      setValueError(true);
      return false;
    }

    if (!issuanceDate) {
      setActiveTab("detalhes-receita");
      setIsSubmit(false);
      setMsgError("Campo Data da Emissão é obrigatório!");
      setShowModalError(true);
      setIssuanceDateError(true);
      return false;
    }

    if (issuanceDate) {
      const dateIsValid = dateIsBetweenRange(
        issuanceDate,
        "",
        extractDateStringFromTimestamp()
      );
      if (!dateIsValid) {
        setActiveTab("detalhes-receita");
        setIsSubmit(false);
        setMsgError("A data de emissão não pode ser maior que a data atual!");
        setShowModalError(true);
        setIssuanceDateError(true);
        return false;
      }
    }

    // Validacoes quando for boleto do banco inter
    if (
      recordType === "billet" &&
      payment === "Boleto" &&
      selectedBankAccountIsInter
    ) {
      const validationResult = await BillToReceiveService.validateGenerateInterBilletFields(
        { dueDate, amount, customerId: customer }
      );

      if (validationResult.error) {
        switch (validationResult.field) {
          case "dueDate":
            setActiveTab("detalhes-receita");
            setDueError(true);
            break;
          case "amount":
            setActiveTab("detalhes-receita");
            setValueError(true);
            break;
          case "customerId":
            setActiveTab("detalhes-receita");
            setCustomerError(true);
            break;
        }

        setMsgError(validationResult.message);
        setIsSubmit(false);
        setShowModalError(true);
        return false;
      }
    }

    return true;
  }

  function handleChangeName(value: string) {
    setName(value);
    setRevenueNameError(value ? false : true);
  }

  function handleChangePayment(value: string) {
    setPayment(value);
    setPayMethodsError(value ? false : true);
  }

  function handleChangeDueDate(value: string) {
    setDueDate(value);
    setDueError(value ? false : true);
  }

  return (
    <div className="row card card-body pt-4 newProductWrapper">
      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />

      <ModalSuccess
        msgModal={msgSuccess}
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
        redirect="/contas-a-receber"
      />

      <AddAccountBankModal
        showModal={showModalAddAccountBank}
        setShowModal={setShowModalAddAccountBank}
        onCreateAccountBank={handleCreateAccountBank}
      />

      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"makeStyles-container-12"}
        autoComplete="off"
      >
        <Tabs
          activeKey={activeTab}
          onSelect={(tab: string) => setActiveTab(tab)}
          id="newproduct-form-tabs"
        >
          <Tab eventKey="detalhes-receita" title="Detalhes da Receita">
            <div className="row">
              <div className="col-lg-3">
                <TextField
                  label="Nome da Receita*"
                  margin="normal"
                  variant="outlined"
                  error={revenueNameError}
                  className={classes.error}
                  value={name}
                  onChange={(e) => handleChangeName(e.target.value)}
                  size="small"
                />
              </div>

              <div className="col-lg-3">
                <ApiResourceSelect
                  label="Conta bancária *"
                  getOptionLabel={(option: BankAccount) =>
                    `${option.nameBank} - ${option.name}`
                  }
                  value={accountBank}
                  hasError={accountBankError}
                  onSelect={handleChangeAccountBank}
                  apiSearchHandler={(typedText) =>
                    BankAccountService.getBankAccountsFiltered({
                      name: typedText,
                      situation: "y",
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!accountBank) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(accountBank)
                      ) ?? BankAccountService.getBankAccountById(accountBank)
                    );
                  }}
                  onChangeTextField={(e) =>
                    setAccountBankError(e.target.value ? false : true)
                  }
                  renderAddButton={
                    <ApiResourceSelectAddButton
                      label="Adicionar Conta Bancária"
                      onClick={() => setShowModalAddAccountBank(true)}
                    />
                  }
                />
              </div>

              <div className="col-lg-3">
                <TextField
                  select
                  label="Forma de Pagamento*"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  error={payMethodsError}
                  className={classes.error}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={payment}
                  onChange={(e) => handleChangePayment(e.target.value)}
                  disabled={recordType === "billet"}
                >
                  <MenuItem key="0" value="">
                    Selecione
                  </MenuItem>

                  {paymentOptions.map((payment, index) => (
                    <MenuItem key={index + 1} value={payment.value}>
                      {payment.value}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div className="col-lg-3">
                <ApiResourceSelect
                  label="Plano de Contas *"
                  getOptionLabel={(option: SubCategory) => option.name}
                  value={dreSubCategoryId}
                  hasError={accountPlanError}
                  onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                  apiSearchHandler={(typedText) =>
                    DreCategoryService.getDreSubCategoriesFiltered(
                      { name: typedText },
                      "revenue"
                    )
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!dreSubCategoryId) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(dreSubCategoryId)
                      ) ??
                      DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                    );
                  }}
                  onChangeTextField={(e) =>
                    setAccountPlanError(e.target.value ? false : true)
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <ApiResourceSelect
                  style={{ width: "100%" }}
                  label="Cliente *"
                  getOptionLabel={(option: Customer) =>
                    `${option.id} - ${option.name}`
                  }
                  value={customer}
                  onSelect={(option) =>
                    setCustomer(option ? String(option.id) : "")
                  }
                  apiSearchHandler={(typedText) =>
                    CustomerService.getCustomersFiltered({
                      name: typedText,
                      nameWithId: true,
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!customer) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(customer)
                      ) ?? CustomerService.getCustomerById(customer)
                    );
                  }}
                  onChangeTextField={(e) =>
                    setCustomerError(e.target.value ? false : true)
                  }
                  textFieldClass={classes.error}
                  hasError={customerError}
                  renderAddButton={(typedText) => (
                    <ApiResourceSelectAddButton
                      label="Adicionar Cliente"
                      onClick={() => handleClickAddCustomer(typedText)}
                    />
                  )}
                />
              </div>

              <div className="col-lg-2">
                <TextField
                  type="date"
                  label="Vencimento*"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={dueError}
                  className={classes.error}
                  value={dueDate}
                  onChange={(e) => handleChangeDueDate(e.target.value)}
                />
              </div>

              <div className="col-lg-2">
                <NumericFormat
                  label="Valor*"
                  startAdornment="R$"
                  error={valueError}
                  className={classes.error}
                  value={amount}
                  onChange={(evt) => {
                    setAmount(evt.target.value);
                    setValueError(evt.target.value ? false : true);
                  }}
                />
              </div>

              <div className="col-lg-2 d-flex align-items-center">
                <TextField
                  select
                  label="Tipo de Registro"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={recordType}
                  onChange={(evt) => handleChangeRecordType(evt.target.value)}
                >
                  <MenuItem key="0" value="account">
                    Conta
                  </MenuItem>

                  <MenuItem key="1" value="billet">
                    Boleto
                  </MenuItem>
                </TextField>
              </div>

              <div className="col-lg-2">
                <TextField
                  type="date"
                  label="Data de emissão *"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={issuanceDate}
                  onChange={(e) => {
                    setIssuanceDate(e.target.value);
                    setIssuanceDateError(e.target.value ? false : true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={issuanceDateError}
                  className={classes.error}
                />
              </div>
            </div>

            <div className="col-lg-2 d-flex align-items-center">
              <FormControlLabel
                label="Pago"
                className="ml-1"
                checked={isPaid}
                onChange={() => setIsPaid((state) => !state)}
                control={<Checkbox color="primary" />}
              />
            </div>
          </Tab>

          <Tab eventKey="detalhes-conta" title="Detalhes da Conta">
            <div className="row">
              <div className="col-lg-6">
                <TextField
                  label="Nº do documento"
                  className="ml-0"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={docNumber}
                  onChange={(evt) => setDocNumber(evt.target.value)}
                />
              </div>

              <div className="col-lg-4">
                <ApiResourceSelect
                  label="Centro de Custos"
                  getOptionLabel={(option: CenterCost) => option.name}
                  value={centerCost}
                  onSelect={(option) =>
                    setCenterCost(option ? String(option.id) : "")
                  }
                  apiSearchHandler={(typedText) =>
                    CenterCostService.getCenterCostsFiltered({
                      name: typedText,
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!centerCost) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(centerCost)
                      ) ?? CenterCostService.getCenterCostById(centerCost)
                    );
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <TextField
                  value={occurrence}
                  select
                  label="Ocorrência"
                  className="ml-0"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    setOccurrence(e.target.value);
                    changeOccurrence(e.target.value);
                  }}
                >
                  <MenuItem key="0" value="unica">
                    Única
                  </MenuItem>

                  <MenuItem key="1" value="parcelada">
                    Parcelada
                  </MenuItem>
                </TextField>
              </div>

              {isInstallment ? (
                <div className="col-lg-3 d-flex align-items-center">
                  <TextField
                    {...register("installments")}
                    size="small"
                    type="number"
                    label="QTD Parcelas"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 1 } }}
                  />

                  <Button
                    type="button"
                    variant="primary"
                    className="mt-2 ml-3"
                    onClick={() => generateInstallment()}
                  >
                    Gerar
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>

            {isGenerateInstallment ? (
              <div className="mt-3 ml-20 mb-3 border-bottom">
                {installments.installments.map((installmentData, index) => {
                  return (
                    <div key={index} className="row">
                      <div className="col-lg-3">
                        <TextField
                          size="small"
                          type="date"
                          label="Data"
                          margin="normal"
                          variant="outlined"
                          value={installmentData.date}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            var allInstallments = installments.installments;
                            allInstallments[index].date = e.target.value;
                            setInstallments({ installments: allInstallments });
                          }}
                        />
                      </div>

                      <div className="col-lg-3">
                        <NumericFormat
                          label="Valor"
                          startAdornment="R$"
                          value={installmentData.value}
                          onChange={(e) => {
                            var allInstallments = installments.installments;
                            allInstallments[index].value = convertToFloat(
                              e.target.value
                            );
                            setInstallments({ installments: allInstallments });
                            distributeInstallments(
                              convertToFloat(e.target.value),
                              index
                            );
                          }}
                          disabled={
                            index + 1 == installments.installments.length
                              ? true
                              : false
                          }
                        />
                      </div>

                      <div className="col-lg-3">
                        <TextField
                          select
                          key={index}
                          value={installmentData.typePayment}
                          label="Forma de Pagamento"
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          size="small"
                          variant="outlined"
                          onChange={(e) => {
                            var allInstallments = installments.installments;
                            allInstallments[index].typePayment = e.target.value;
                            setInstallments({ installments: allInstallments });
                          }}
                        >
                          <MenuItem key="0" value="">
                            Selecione
                          </MenuItem>

                          {paymentOptions.map((payment, index) => (
                            <MenuItem key={index + 1} value={payment.value}>
                              {payment.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}

            <div className="row">
              <div className="col-lg-5">
                <UploadFiles
                  label="Anexos"
                  filesSelected={filesSelected}
                  setFilesSelected={setFilesSelected}
                  filesNamesSaved={filesNamesSaved}
                  setFilesNamesSaved={setFilesNamesSaved}
                />
              </div>
            </div>

            <TextField
              value={comments}
              onChange={(evt) => setComments(evt.target.value)}
              label="Observações"
              multiline
              rows="2"
              className="ml-0"
              margin="normal"
              variant="outlined"
              size="small"
            />
          </Tab>
        </Tabs>

        <div className="col-lg-6 mt-15 d-flex flex-row">
          <Button
            type="submit"
            className="mr-3"
            variant="primary"
            disabled={isSubmit}
          >
            {isSubmit ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />

                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <>
                <span>Salvar</span>
              </>
            )}
          </Button>

          <Link href="/contas-a-receber" className="btn btn-secondary">
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
}
