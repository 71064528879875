import React, { useState, useEffect } from 'react';

import {
   Grid,
   TextField,
   MenuItem,
   makeStyles,
   InputAdornment   // IconButton,
} from '@material-ui/core';

// import EditIcon from '@material-ui/icons/Edit';

import { NumericFormat } from '../../../components/NumericFormat';
import { useForm } from 'react-hook-form';

import { Card } from 'react-bootstrap';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
   container: {
      display: 'flex',
      flexWrap: 'wrap',
   },
   textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
   },
   dense: {
      marginTop: theme.spacing(2),
   },
   menu: {
      width: 200,
   },
}));

type ncmNomenclature = {
   Codigo: string,
   Descricao: string,
   Data_Inicio: string,
   Data_Fim: string,
   Tipo_Ato: string,
   Numero_Ato: string,
   Ano_Ato: string
}

type ncm = {
   code: string,
   description: string
}

type cestCodeFetch = {
   numeroAnexo: string,
   descAnexo: string,
   item: string,
   cest: string,
   ncms: string[],
   descricao: string,
}

type cestCode = {
   code: string,
   description: string
}

type Inputs = {
   ncm: string,
   cestCode: string,
   name: string;
   code: string;
   isVariant: string;
   brand: string;
   unit: string;
   codeGtinEan: string;
   stockLocation: string;
   stockMin: string;
   stockMax: string;
   grossWeight: string;
   liquidColumn: string;
   saleValue: string;
   costValue: string;
   productSize: string;
   productOrigin: string;
   ordersNumber: string;
   typeClassification: string;
   situation: string;
   type: string;
   provider: string;
   stockInitial: string;
   note: string;
   category: string;
   icmsRate: string;
   icmsStRate: string;
   cstRate: string;
   mvaRate: string;
   mvaAdjustedRate: string;
   deferralRate: string;
   ipiRate: string;
   ipiCst: string;
   pisRate: string;
   pisCst: string;
   cofinsRate: string;
   cofinsCst: string;
   taxableUnit: string;
   taxableCount: string;
   taxableValue: string;
   taxBenefitCode: string;
   federal: string;
   state: string;

   nameCategory: string;
   nameSubCategory: string;
   descriptionCategory: string;
   statusCategory: string;
};

type Props = {
   ncm: string;
   setNcm: React.Dispatch<React.SetStateAction<string>>;
   cestCode: string;
   setCestCode: React.Dispatch<React.SetStateAction<string>>;
   icms: string;
   setIcms: React.Dispatch<React.SetStateAction<string>>;
   icmsSt: string;
   setIcmsSt: React.Dispatch<React.SetStateAction<string>>;
   cst: string;
   setCst: React.Dispatch<React.SetStateAction<string>>;
   mva: string;
   setMva: React.Dispatch<React.SetStateAction<string>>;
   mvaAdjusted: string;
   setMvaAdjusted: React.Dispatch<React.SetStateAction<string>>;
   deferral: string;
   setDeferral: React.Dispatch<React.SetStateAction<string>>;
   ipi: string;
   setIpi: React.Dispatch<React.SetStateAction<string>>;
   pis: string;
   setPis: React.Dispatch<React.SetStateAction<string>>;
   cofins: string;
   setCofins: React.Dispatch<React.SetStateAction<string>>;
   cofinsCst: string;
   setCofinsCst: React.Dispatch<React.SetStateAction<string>>;
   federal: string;
   setFederal: React.Dispatch<React.SetStateAction<string>>;
   state: string;
   setState: React.Dispatch<React.SetStateAction<string>>;
   ipiCst: string;
   setIpiCst: React.Dispatch<React.SetStateAction<string>>;
   pisCst: string;
   setPisCst: React.Dispatch<React.SetStateAction<string>>;
   taxableValue: string;
   setTaxableValue: React.Dispatch<React.SetStateAction<string>>;

   handleChange: (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
   ) => void;
};

export function NewProductInvoice({
   ncm,
   setNcm,
   cestCode,
   setCestCode,
   icms,
   setIcms,
   icmsSt,
   setIcmsSt,
   cst,
   setCst,
   mva,
   setMva,
   mvaAdjusted,
   setMvaAdjusted,
   deferral,
   setDeferral,
   ipi,
   setIpi,
   ipiCst,
   setIpiCst,
   pis,
   setPis,
   pisCst,
   setPisCst,
   cofins,
   setCofins,
   cofinsCst,
   setCofinsCst,
   federal,
   setFederal,
   state,
   setState,
   taxableValue,
   setTaxableValue,
   handleChange
}: Props) {
   const classes = useStyles();
   const {
      register,
      formState: { errors },
   } = useForm<Inputs>();

   const [ncmList, setNcmList] = useState<ncm[]>([]);
   const [cestCodeList, setCestCodeList] = useState<cestCode[]>([]);

   function getNcmList() {
      var json = require("../../../../json/ncm-list.json");
      var nomenclatures: ncmNomenclature[] = json.Nomenclaturas;

      /**
       * ncms de 8 digitos
       */
      // var nomenclaturesComplete = nomenclatures.filter((obj) => obj.Codigo.replaceAll('.', '').length === 8);

      var ncm: ncm[] = [];
      nomenclatures.map((obj, index) => {
         if (obj.Codigo.replaceAll('.', '').length !== 8) {
            return;
         }
         var description: string = '';
         var objCode = obj.Codigo.replaceAll('.', '');
         var smallArray = nomenclatures.slice(((index - 200) >= 0 ? (index - 200) : 0), index);

         var partialNomenclatures = smallArray.filter((obj2) => {
            var obj2Code = obj2.Codigo.replaceAll('.', '');
            return obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 2)
               || obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 3)
               || obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 4)
               || obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 5)
               || obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 6)
               || obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 7);
         });
         partialNomenclatures.map((obj3) => {
            description += ' ' + obj3.Descricao;
         });
         description += ' ' + obj.Descricao;
         ncm.push({
            code: obj.Codigo,
            description: description
         });
      });

      setNcmList(ncm);
   }

   function getCestCodeList() {
      var json = require("../../../../json/cest-list.json");
      var cestFetch: cestCodeFetch[] = json;

      var cestCode: cestCode[] = [];
      cestFetch.map((obj, index) => {
         cestCode.push({
            code: obj.cest,
            description: obj.descAnexo + ' - ' + obj.descricao
         });
      });

      setCestCodeList(cestCode);
   }

   function updateCestCodeList(selectedNcm: ncm | null) {
      if (!selectedNcm) {
         getCestCodeList();
         return;
      }

      var json = require("../../../../json/cest-list.json");
      var cestFetch: cestCodeFetch[] = json;
      var list: cestCode[] = [];
      var cestFiltered = cestFetch.filter((obj) => {
         return obj.ncms.findIndex((ncmStr) => ncmStr === selectedNcm.code.replaceAll('.', '').substring(0, ncmStr.length)) !== -1;
      });

      if (cestFiltered.length === 0) {
         getCestCodeList();
         return;
      }

      cestFiltered.map((filteredObj) => {
         list.push({
            code: filteredObj.cest,
            description: filteredObj.descAnexo + ' - ' + filteredObj.descricao
         });
      });

      setCestCodeList(list);
   }

   function formatToFloat(value: string) {
      if (value.includes(',') && !value.includes('.')) {
         return parseFloat(value.replace(',', '.'));

      } else {
         return parseFloat(value.replace('.', '').replace(',', '.'));
      }
   }

   const formatDecimal = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2
   })

   useEffect(() => {
      getNcmList()
      getCestCodeList()
   }, []);

   return (
      <div className='newProductWrapper'>
         <div className='col-lg-12 p-0'>
            <Card.Body className="p-0">
               <div className='row'>
                  <div className='col-lg-12'>
                     <TextField
                        size="small"
                        id='outlined-select-currency'
                        select
                        label='Origem do produto'
                        SelectProps={{
                           MenuProps: {
                              className: classes.menu,
                           },
                        }}
                        margin='normal'
                        variant='outlined'
                        {...register('productOrigin')}
                        onChange={(e) => handleChange(e)}
                     >
                        <MenuItem key='0' value='0 - Nacional'>
                           0 - Nacional
                        </MenuItem>

                        <MenuItem
                           key='1'
                           value='1 - Estrangeira - Importação direta'
                        >
                           1 - Estrangeira - Importação direta
                        </MenuItem>

                        <MenuItem
                           key='2'
                           value='2 - Estrangeira - Adquirida no mercado interno'
                        >
                           2 - Estrangeira - Adquirida no mercado interno
                        </MenuItem>

                        <MenuItem
                           key='3'
                           value='3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%'
                        >
                           3 - Nacional, mercadoria ou bem com Conteúdo de
                           Importação superior a 40%
                        </MenuItem>

                        <MenuItem
                           key='4'
                           value='4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes'
                        >
                           4 - Nacional, cuja produção tenha sido feita em
                           conformidade com os processos produtivos
                           básicos de que tratam as legislações citadas
                           nos Ajustes
                        </MenuItem>

                        <MenuItem
                           key='5'
                           value='5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%'
                        >
                           5 - Nacional, mercadoria ou bem com Conteúdo de
                           Importação inferior ou igual a 40%
                        </MenuItem>

                        <MenuItem
                           key='6'
                           value='6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX'
                        >
                           6 - Estrangeira - Importação direta, sem
                           similar nacional, constante em lista da CAMEX
                        </MenuItem>

                        <MenuItem
                           key='7'
                           value='7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX'
                        >
                           7 - Estrangeira - Adquirida no mercado interno,
                           sem similar nacional, constante em lista da
                           CAMEX
                        </MenuItem>

                        <MenuItem
                           key='8'
                           value='8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%'
                        >
                           8 - Nacional, mercadoria ou bem com Conteúdo de
                           Importação superior a 70%
                        </MenuItem>
                     </TextField>
                  </div>

                  {/* <div className='col-lg-2'>
                        <TextField
                           size="small"
                           select
                           label='Unidade Tributável'
                           className='ml-0'
                           SelectProps={{
                              MenuProps: {
                                 className: classes.menu,
                              },
                           }}
                           margin='normal'
                           variant='outlined'
                           {...register('taxableUnit')}
                           onChange={(e) => handleChange(e)}
                        >
                           <MenuItem key='0' value='Pç'>
                              Pç
                           </MenuItem>

                           <MenuItem key='1' value='Kg'>
                              Kg
                           </MenuItem>

                           <MenuItem key='1' value='Un'>
                              Un
                           </MenuItem>
                        </TextField>
                     </div>

                     <div className='col-lg-2'>
                        <TextField
                           size="small"
                           type="number"
                           label='Quantidade Tributável'
                           className='ml-0'
                           margin='normal'
                           variant='outlined'
                           {...register('taxableCount')}
                           onChange={(e) => handleChange(e)}
                        />
                     </div>

                     <div className='col-lg-2'>
                        <NumericFormat
                              label="Valor Tributável"
                              decimalScale={10}
                              startAdornment="R$"
                              value={taxableValue} 
                              onChange={(e) => {
                                 setTaxableValue(e.target.value);
                              }}
                        />
                     </div> */}

                  <div className='col-lg-12'>
                     <Autocomplete
                        size="small"
                        noOptionsText="Sem opções"
                        options={ncmList}
                        renderOption={(option) => option.code + ' - ' + option.description}
                        style={{ width: "100%" }}
                        getOptionLabel={(option) => option.code + ' - ' + option.description}
                        onChange={(e, val) => {
                           setNcm(val ? val.code : "");
                           updateCestCodeList(val);
                        }}
                        value={ncmList.find(ncmData => String(ncmData.code) == String(ncm)) ?? { code: "", description: "" }}
                        renderInput={(params) =>
                           <TextField
                              size="small"
                              {...params}
                              label='NCM'
                              margin='normal'
                              variant='outlined'
                           />
                        }
                     />
                     {/* {errors.ncm && errors.ncm.type === 'pattern' && (
                           <span className='text-danger pl-3'>
                              {errors.ncm.message}
                           </span>
                        )} */}
                  </div>

                  <div className='col-lg-6'>
                     <Autocomplete
                        size="small"
                        noOptionsText="Sem opções"
                        options={cestCodeList}
                        renderOption={(option) => option.code + ' - ' + option.description}
                        style={{ width: "100%" }}
                        getOptionLabel={(option) => option.code ?? ''}
                        onChange={(e, val) => { setCestCode(val ? val.code : "") }}
                        value={cestCodeList.find(cest => String(cest.code) == String(cestCode)) ?? { code: '', description: '' }}
                        renderInput={(params) =>
                           <TextField
                              size="small"
                              {...params}
                              label='Código CEST'
                              placeholder='Código Especificador ST'
                              margin='normal'
                              variant='outlined'
                           // {...register('cestCode')}
                           />
                        }
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="Peso bruto"
                        startAdornment="KG"
                        {...register("grossWeight")}
                        onChange={(e) => handleChange(e)}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="Peso líquido"
                        startAdornment="KG"
                        {...register("liquidColumn")}
                        onChange={(e) => handleChange(e)}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="ICMS"
                        startAdornment="%"
                        value={icms}
                        onChange={(e) => setIcms(e.target.value)}
                        onBlur={(e) => {
                           var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                           if (converted > 100) {
                              setIcms("100");
                           } else {
                              setIcms(converted ? formatDecimal.format(converted) : "");
                           }
                        }}
                     />
                  </div>


                  <div className='col-lg-2'>
                     <NumericFormat
                        label="ICMS-ST"
                        startAdornment="%"
                        value={icmsSt}
                        onChange={(e) => setIcmsSt(e.target.value)}
                        onBlur={(e) => {
                           var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                           if (converted > 100) {
                              setIcmsSt("100");
                           } else {
                              setIcmsSt(converted ? formatDecimal.format(converted) : "");
                           }
                        }}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="MVA"
                        startAdornment="%"
                        value={mva}
                        onChange={(e) => setMva(e.target.value)}
                        onBlur={(e) => {
                           var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                           if (converted > 100) {
                              setMva("100");
                           } else {
                              setMva(converted ? formatDecimal.format(converted) : "");
                           }
                        }}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="MVA ajustado"
                        startAdornment="%"
                        value={mvaAdjusted}
                        onChange={(e) => setMvaAdjusted(e.target.value)}
                        onBlur={(e) => {
                           var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                           if (converted > 100) {
                              setMvaAdjusted("100");
                           } else {
                              setMvaAdjusted(converted ? formatDecimal.format(converted) : "");
                           }
                        }}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="Diferimento"
                        startAdornment="%"
                        value={deferral}
                        onChange={(e) => setDeferral(e.target.value)}
                        onBlur={(e) => {
                           var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                           if (converted > 100) {
                              setDeferral("100");
                           } else {
                              setDeferral(converted ? formatDecimal.format(converted) : "");
                           }
                        }}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="IPI"
                        startAdornment="%"
                        value={ipi}
                        onChange={(e) => setIpi(e.target.value)}
                        onBlur={(e) => {
                           var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                           if (converted > 100) {
                              setIpi("100");
                           } else {
                              setIpi(converted ? formatDecimal.format(converted) : "");
                           }
                        }}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <TextField
                        size="small"
                        label='Código benefício fiscal'
                        className='ml-0'
                        margin='normal'
                        variant='outlined'
                        {...register('taxBenefitCode')}
                        onChange={(e) => handleChange(e)}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="Federal"
                        startAdornment="%"
                        value={federal}
                        onChange={(e) => setFederal(e.target.value)}
                        onBlur={(e) => {
                           var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                           if (converted > 100) {
                              setFederal("100");
                           } else {
                              setFederal(converted ? formatDecimal.format(converted) : "");
                           }
                        }}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="Estadual"
                        startAdornment="%"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        onBlur={(e) => {
                           var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                           if (converted > 100) {
                              setState("100");
                           } else {
                              setState(converted ? formatDecimal.format(converted) : "");
                           }
                        }}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="COFINS"
                        startAdornment="%"
                        value={cofins}
                        onChange={(e) => setCofins(e.target.value)}
                        onBlur={(e) => {
                           var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                           if (converted > 100) {
                              setCofins("100");
                           } else {
                              setCofins(converted ? formatDecimal.format(converted) : "");
                           }
                        }}
                     />
                  </div>

                  <div className='col-lg-2'>
                     <NumericFormat
                        label="PIS"
                        startAdornment="%"
                        value={pis}
                        onChange={(e) => setPis(e.target.value)}
                        onBlur={(e) => {
                           var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                           if (converted > 100) {
                              setPis("100");
                           } else {
                              setPis(converted ? formatDecimal.format(converted) : "");
                           }
                        }}
                     />
                  </div>
               </div>

               <div className="row mt-3">
                  <div className="col bg-warning-o-30 p-5 mb-3">
                     <p>
                        <strong>Atenção: </strong>
                        Se os campos abaixos não estiverem preenchidos ao emitir uma nota fiscal, será utilizado os parâmetros padrões configurados em
                        <strong> Dados da Empresa -{'>'} Parâmetros </strong>
                     </p>
                  </div>
               </div>

               <div className="row">
                  <div className='col-lg-4'>
                     <TextField
                        size="small"
                        select
                        label='ICMS-CST'
                        className='ml-0'
                        SelectProps={{
                           MenuProps: {
                              className: classes.menu,
                           },
                        }}
                        margin='normal'
                        variant='outlined'
                        value={cst}
                        onChange={(e) => setCst(e.target.value)}
                     >
                        <MenuItem value=''>
                           Selecione
                        </MenuItem>

                        <MenuItem value='00 - Tributada integralmente'>
                           00 - Tributada integralmente
                        </MenuItem>

                        <MenuItem value='10 - Tributada e com cobrança do ICMS por substituição tributária'>
                           10 - Tributada e com cobrança do ICMS por substituição tributária
                        </MenuItem>

                        <MenuItem value='10_partilha - Tributada e com cobrança do ICMS por substituição tributária. Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)'>
                           10_partilha - Tributada e com cobrança do ICMS por substituição tributária. Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)
                        </MenuItem>

                        <MenuItem value='20 - Tributada com redução de base de cálculo'>
                           20 - Tributada com redução de base de cálculo
                        </MenuItem>

                        <MenuItem value='30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária'>
                           30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária
                        </MenuItem>

                        <MenuItem value='40 - Isenta'>
                           40 - Isenta
                        </MenuItem>

                        <MenuItem value='41 - Não tributada'>
                           41 - Não tributada
                        </MenuItem>

                        <MenuItem value='41_st - Não tributada com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário'>
                           41_st - Não tributada com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário
                        </MenuItem>

                        <MenuItem value='50 - Com suspensão'>
                           50 - Com suspensão
                        </MenuItem>

                        <MenuItem value='51 - Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)'>
                           51 - Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)
                        </MenuItem>

                        <MenuItem value='60 - Cobrado anteriormente por substituição tributária'>
                           60 - Cobrado anteriormente por substituição tributária
                        </MenuItem>

                        <MenuItem value='60_st - Cobrado anteriormente por substituição tributária com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário'>
                           60_st - Cobrado anteriormente por substituição tributária com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário
                        </MenuItem>

                        <MenuItem value='70 - Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária'>
                           70 - Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária
                        </MenuItem>

                        <MenuItem value='90 - Outras (regime Normal)'>
                           90 - Outras (regime Normal)
                        </MenuItem>

                        <MenuItem value='90_partilha - Outras (regime Normal). Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)'>
                           90_partilha - Outras (regime Normal). Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)
                        </MenuItem>

                        <MenuItem value='101 - Tributada pelo Simples Nacional com permissão de crédito'>
                           101 - Tributada pelo Simples Nacional com permissão de crédito
                        </MenuItem>

                        <MenuItem value='102 - Tributada pelo Simples Nacional com permissão de crédito'>
                           102 - Tributada pelo Simples Nacional com permissão de crédito
                        </MenuItem>

                        <MenuItem value='103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta'>
                           103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta
                        </MenuItem>

                        <MenuItem value='201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária'>
                           201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária
                        </MenuItem>

                        <MenuItem value='202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária'>
                           202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária
                        </MenuItem>

                        <MenuItem value='203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária'>
                           203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária
                        </MenuItem>

                        <MenuItem value='300 - Imune'>
                           300 - Imune
                        </MenuItem>

                        <MenuItem value='400 - Não tributada pelo Simples Nacional'>
                           400 - Não tributada pelo Simples Nacional
                        </MenuItem>

                        <MenuItem value='500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação'>
                           500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação
                        </MenuItem>

                        <MenuItem value='900 - Outras (regime Simples Nacional)'>
                           900 - Outras (regime Simples Nacional)
                        </MenuItem>
                     </TextField>
                  </div>

                  <div className='col-lg-4'>
                     <TextField
                        size="small"
                        select
                        label='IPI-CST'
                        className='ml-0'
                        SelectProps={{
                           MenuProps: {
                              className: classes.menu,
                           },
                        }}
                        margin='normal'
                        variant='outlined'
                        value={ipiCst}
                        onChange={(e) => setIpiCst(e.target.value)}
                     >
                        <MenuItem value=''>
                           Selecione
                        </MenuItem>

                        <MenuItem value='00 - Entrada com recuperação de crédito'>
                           00 - Entrada com recuperação de crédito
                        </MenuItem>

                        <MenuItem value='01 - Entrada tributada com alíquota zero'>
                           01 - Entrada tributada com alíquota zero
                        </MenuItem>

                        <MenuItem value='02 - Entrada isenta'>
                           02 - Entrada isenta
                        </MenuItem>

                        <MenuItem value='03 - Entrada não-tributada'>
                           03 - Entrada não-tributada
                        </MenuItem>

                        <MenuItem value='04 - Entrada imune'>
                           04 - Entrada imune
                        </MenuItem>

                        <MenuItem value='05 - Entrada com suspensão'>
                           05 - Entrada com suspensão
                        </MenuItem>

                        <MenuItem value='49 - Outras entradas'>
                           49 - Outras entradas
                        </MenuItem>

                        <MenuItem value='50 - Saída tributada'>
                           50 - Saída tributada
                        </MenuItem>

                        <MenuItem value='51 - Saída tributada com alíquota zero'>
                           51 - Saída tributada com alíquota zero
                        </MenuItem>

                        <MenuItem value='52 - Saída isenta'>
                           52 - Saída isenta
                        </MenuItem>

                        <MenuItem value='53 - Saída não-tributada'>
                           53 - Saída não-tributada
                        </MenuItem>

                        <MenuItem value='54 - Saída imune'>
                           54 - Saída imune
                        </MenuItem>

                        <MenuItem value='55 - Saída com suspensão'>
                           55 - Saída com suspensão
                        </MenuItem>

                        <MenuItem value='99 - Outras saídas'>
                           99 - Outras saídas
                        </MenuItem>
                     </TextField>
                  </div>

                  <div className='col-lg-4'>
                     <TextField
                        size="small"
                        select
                        label='PIS-CST'
                        className='ml-0'
                        SelectProps={{
                           MenuProps: {
                              className: classes.menu,
                           },
                        }}
                        margin='normal'
                        variant='outlined'
                        value={pisCst}
                        onChange={(e) => setPisCst(e.target.value)}
                     >
                        <MenuItem value=''>
                           Selecione
                        </MenuItem>

                        <MenuItem value='01 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)'>
                           01 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)
                        </MenuItem>

                        <MenuItem value='02 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)'>
                           02 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)
                        </MenuItem>

                        <MenuItem value='03 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto'>
                           03 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto
                        </MenuItem>

                        <MenuItem value='04 - Operação tributável: tributação monofásica (alíquota zero)'>
                           04 - Operação tributável: tributação monofásica (alíquota zero)
                        </MenuItem>

                        <MenuItem value='05 - Operação tributável: substituição tributária'>
                           05 - Operação tributável: substituição tributária
                        </MenuItem>

                        <MenuItem value='06 - Operação tributável: alíquota zero'>
                           06 - Operação tributável: alíquota zero
                        </MenuItem>

                        <MenuItem value='07 - Operação isenta da contribuição'>
                           07 - Operação isenta da contribuição
                        </MenuItem>

                        <MenuItem value='08 - Operação sem incidência da contribuição'>
                           08 - Operação sem incidência da contribuição
                        </MenuItem>

                        <MenuItem value='09 - Operação com suspensão da contribuição'>
                           09 - Operação com suspensão da contribuição
                        </MenuItem>

                        <MenuItem value='49 - Outras operações de saída'>
                           49 - Outras operações de saída
                        </MenuItem>

                        <MenuItem value='50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno'>
                           50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno
                        </MenuItem>

                        <MenuItem value='51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno'>
                           51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno
                        </MenuItem>

                        <MenuItem value='52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação'>
                           52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação
                        </MenuItem>

                        <MenuItem value='53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno'>
                           53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno
                        </MenuItem>

                        <MenuItem value='54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação'>
                           54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação'>
                           55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação'>
                           56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno'>
                           60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno
                        </MenuItem>

                        <MenuItem value='61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno'>
                           61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno
                        </MenuItem>

                        <MenuItem value='62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação'>
                           62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação
                        </MenuItem>

                        <MenuItem value='63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno'>
                           63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno
                        </MenuItem>

                        <MenuItem value='64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação'>
                           64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação'>
                           65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação'>
                           66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='67 - Crédito presumido: outras operações'>
                           67 - Crédito presumido: outras operações
                        </MenuItem>

                        <MenuItem value='70 - Operação de aquisição sem direito a crédito'>
                           70 - Operação de aquisição sem direito a crédito
                        </MenuItem>

                        <MenuItem value='71 - Operação de aquisição com isenção'>
                           71 - Operação de aquisição com isenção
                        </MenuItem>

                        <MenuItem value='72 - Operação de aquisição com suspensão'>
                           72 - Operação de aquisição com suspensão
                        </MenuItem>

                        <MenuItem value='73 - Operação de aquisição a alíquota zero'>
                           73 - Operação de aquisição a alíquota zero
                        </MenuItem>

                        <MenuItem value='74 - Operação de aquisição sem incidência da contribuição'>
                           74 - Operação de aquisição sem incidência da contribuição
                        </MenuItem>

                        <MenuItem value='75 - Operação de aquisição por substituição tributária'>
                           75 - Operação de aquisição por substituição tributária
                        </MenuItem>

                        <MenuItem value='98 - Outras operações de entrada'>
                           98 - Outras operações de entrada
                        </MenuItem>

                        <MenuItem value='99 - Outras operações'>
                           99 - Outras operações
                        </MenuItem>
                     </TextField>
                  </div>

                  <div className='col-lg-4'>
                     <TextField
                        size="small"
                        select
                        label='COFINS-CST'
                        className='ml-0'
                        SelectProps={{
                           MenuProps: {
                              className: classes.menu,
                           },
                        }}
                        margin='normal'
                        variant='outlined'
                        value={cofinsCst}
                        onChange={(e) => setCofinsCst(e.target.value)}
                     >
                        <MenuItem value=''>
                           Selecione
                        </MenuItem>

                        <MenuItem value='01 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)'>
                           01 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)
                        </MenuItem>

                        <MenuItem value='02 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)'>
                           02 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)
                        </MenuItem>

                        <MenuItem value='03 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto'>
                           03 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto
                        </MenuItem>

                        <MenuItem value='04 - Operação tributável: tributação monofásica (alíquota zero)'>
                           04 - Operação tributável: tributação monofásica (alíquota zero)
                        </MenuItem>

                        <MenuItem value='05 - Operação tributável: substituição tributária'>
                           05 - Operação tributável: substituição tributária
                        </MenuItem>

                        <MenuItem value='06 - Operação tributável: alíquota zero'>
                           06 - Operação tributável: alíquota zero
                        </MenuItem>

                        <MenuItem value='07 - Operação isenta da contribuição'>
                           07 - Operação isenta da contribuição
                        </MenuItem>

                        <MenuItem value='08 - Operação sem incidência da contribuição'>
                           08 - Operação sem incidência da contribuição
                        </MenuItem>

                        <MenuItem value='09 - Operação com suspensão da contribuição'>
                           09 - Operação com suspensão da contribuição
                        </MenuItem>

                        <MenuItem value='49 - Outras operações de saída'>
                           49 - Outras operações de saída
                        </MenuItem>

                        <MenuItem value='50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno'>
                           50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno
                        </MenuItem>

                        <MenuItem value='51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno'>
                           51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno
                        </MenuItem>

                        <MenuItem value='52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação'>
                           52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação
                        </MenuItem>

                        <MenuItem value='53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno'>
                           53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno
                        </MenuItem>

                        <MenuItem value='54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação'>
                           54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação'>
                           55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação'>
                           56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno'>
                           60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno
                        </MenuItem>

                        <MenuItem value='61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno'>
                           61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno
                        </MenuItem>

                        <MenuItem value='62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação'>
                           62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação
                        </MenuItem>

                        <MenuItem value='63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno'>
                           63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno
                        </MenuItem>

                        <MenuItem value='64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação'>
                           64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação'>
                           65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação'>
                           66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação
                        </MenuItem>

                        <MenuItem value='67 - Crédito presumido: outras operações'>
                           67 - Crédito presumido: outras operações
                        </MenuItem>

                        <MenuItem value='70 - Operação de aquisição sem direito a crédito'>
                           70 - Operação de aquisição sem direito a crédito
                        </MenuItem>

                        <MenuItem value='71 - Operação de aquisição com isenção'>
                           71 - Operação de aquisição com isenção
                        </MenuItem>

                        <MenuItem value='72 - Operação de aquisição com suspensão'>
                           72 - Operação de aquisição com suspensão
                        </MenuItem>

                        <MenuItem value='73 - Operação de aquisição a alíquota zero'>
                           73 - Operação de aquisição a alíquota zero
                        </MenuItem>

                        <MenuItem value='74 - Operação de aquisição sem incidência da contribuição'>
                           74 - Operação de aquisição sem incidência da contribuição
                        </MenuItem>

                        <MenuItem value='75 - Operação de aquisição por substituição tributária'>
                           75 - Operação de aquisição por substituição tributária
                        </MenuItem>

                        <MenuItem value='98 - Outras operações de entrada'>
                           98 - Outras operações de entrada
                        </MenuItem>

                        <MenuItem value='99 - Outras operações'>
                           99 - Outras operações
                        </MenuItem>
                     </TextField>
                  </div>
               </div>


            </Card.Body>
         </div>
      </div>
   )
}