import { CompanyAccountant } from "../types/CompanyAccountant";
import { GenericObject } from "../types/GenericObject";
import { yesOrNo } from "../types/yesOrNo";
import { SanitizeInputType, sanitizeInput } from "../utils/sanitizeInput";
import api from "./Api";
import UserService from "./UserService";

type Options = {
  withSubscriptionPlan?: boolean;
  withNfApiConfig?: boolean;
  withMainCompany?: boolean;
  filters?: Filters;
};

export type Filters = {
  searchQuery?: string;
  company?: string;
  name?: string;
  cpf?: string;
  cnpj?: string;
};

type LoadDataParams = {
  rowsPerPage: number;
  currentPage: number;
  sortDirection: "ASC" | "DESC";
  sortReference: string;
};

class CompanyService {
  async getCompanies({ withSubscriptionPlan }: Options = {}) {
    const { data } = await api.get("/companies");

    if (withSubscriptionPlan) {
      const mappedCompanies = await this.setSubscriptionPlans(data);
      return mappedCompanies;
    }

    return data;
  }

  async getCompaniesFiltered({ withSubscriptionPlan, filters }: Options = {}) {
    const { data } = await api.get("/companies", { params: { filters } });

    if (withSubscriptionPlan) {
      const mappedCompanies = await this.setSubscriptionPlans(data);
      return mappedCompanies;
    }

    return data;
  }

  async getCompaniesByIdsArray(ids: (string | number)[]) {
    const { data } = await api.get<any[]>("/companies/ids", {
      params: { ids },
    });

    return data;
  }

  async getCompaniesWithPagination(
    { rowsPerPage, currentPage, sortDirection, sortReference }: LoadDataParams,
    filters: Filters | null,
    { withSubscriptionPlan }: Options = {}
  ) {
    const { data } = await api.get<{ rows: any[]; count: number }>(
      "/companies",
      {
        params: {
          skip: rowsPerPage * currentPage,
          take: rowsPerPage,
          filters: filters ? JSON.stringify(filters) : undefined,
          sortReference,
          sortDirection,
        },
      }
    );

    const { rows, count } = data;

    if (withSubscriptionPlan) {
      const mappedCompanies = await this.setSubscriptionPlans(rows);
      return { rows: mappedCompanies, count };
    }

    return { rows, count };
  }

  async getCompanyById(
    id: string | number,
    { withSubscriptionPlan, withNfApiConfig }: Options = {}
  ) {
    const { data } = await api.get(`/companies/${id}`, {
      params: { withNfApiConfig },
    });

    if (withSubscriptionPlan) {
      const [mappedCompany] = await this.setSubscriptionPlans([data]);
      return mappedCompany;
    }

    return data;
  }

  async getCompanyBranches(
    mainCompanyId: string | number,
    { withSubscriptionPlan, withNfApiConfig }: Options = {}
  ) {
    const { data } = await api.get(`/companies/branches/${mainCompanyId}`, {
      params: { withNfApiConfig },
    });
    console.log("@@##2", mainCompanyId, data);

    if (withSubscriptionPlan) {
      const mappedCompanies = await this.setSubscriptionPlans(data);
      return mappedCompanies;
    }

    return data;
  }

  async getCompanyBranchesWithPagination(
    mainCompanyId: string | number,
    { rowsPerPage, currentPage, sortDirection, sortReference }: LoadDataParams,
    filters: Filters | null,
    { withSubscriptionPlan, withNfApiConfig }: Options = {}
  ) {
    const { data } = await api.get<{ rows: any[]; count: number }>(
      `/companies/branches/${mainCompanyId}`,
      {
        params: {
          withNfApiConfig,
          skip: rowsPerPage * currentPage,
          take: rowsPerPage,
          filters: filters ? JSON.stringify(filters) : undefined,
          sortReference,
          sortDirection,
        },
      }
    );

    const { rows, count } = data;

    if (withSubscriptionPlan) {
      const mappedCompanies = await this.setSubscriptionPlans(rows);
      return { rows: mappedCompanies, count };
    }

    return { rows, count };
  }

  async getMainCompanyAndCompanyBranches(
    mainCompanyId: string | number,
    { withSubscriptionPlan, withNfApiConfig }: Options = {}
  ) {
    const { data } = await api.get<any[]>(
      `/companies/main-and-branches/${mainCompanyId}`,
      {
        params: { withNfApiConfig },
      }
    );

    if (withSubscriptionPlan) {
      const mappedCompanies = await this.setSubscriptionPlans(data);
      return mappedCompanies;
    }

    return data;
  }

  async saveCompanyAccountant(accountant: CompanyAccountant, company: any) {
    const accountantRaw: GenericObject = {
      companyId: company.id,
      userId: accountant.userId ? Number(accountant.userId) : null,
      name: accountant.name ? accountant.name : null,
      cpf: accountant.cpf
        ? sanitizeInput(SanitizeInputType.NUMERIC, accountant.cpf)
        : null,
      cnpj: accountant.cnpj
        ? sanitizeInput(SanitizeInputType.NUMERIC, accountant.cnpj)
        : null,
      crc: accountant.crc ? accountant.crc : null,
      cell: accountant.cell
        ? sanitizeInput(SanitizeInputType.NUMERIC, accountant.cell)
        : null,
      phone: accountant.phone
        ? sanitizeInput(SanitizeInputType.NUMERIC, accountant.phone)
        : null,
      email: accountant.email ? accountant.email : null,
      zipCode: accountant.zipCode ? accountant.zipCode : null,
      address: accountant.address ? accountant.address : null,
      number: accountant.number ? accountant.number : null,
      district: accountant.district ? accountant.district : null,
      complement: accountant.complement ? accountant.complement : null,
      city: accountant.city ? accountant.city : null,
      state: accountant.state ? accountant.state : null,
    };

    let savedAccountant = null;

    if (accountant.id) {
      const { data } = await api.put(
        `/companyAccountants/${accountant.id}`,
        accountantRaw
      );
      savedAccountant = data;
    } else {
      const { data } = await api.post(`/companyAccountants`, accountantRaw);
      savedAccountant = data;
      accountant.id = data.id;
    }

    return savedAccountant;
  }

  async saveCompanyAccountantAndUpdateUser(
    accountant: CompanyAccountant,
    company: any
  ) {
    const accountantRaw: GenericObject = {
      companyId: company.id,
      name: accountant.name ? accountant.name : null,
      cpf: accountant.cpf
        ? sanitizeInput(SanitizeInputType.NUMERIC, accountant.cpf)
        : null,
      cnpj: accountant.cnpj
        ? sanitizeInput(SanitizeInputType.NUMERIC, accountant.cnpj)
        : null,
      crc: accountant.crc ? accountant.crc : null,
      cell: accountant.cell
        ? sanitizeInput(SanitizeInputType.NUMERIC, accountant.cell)
        : null,
      phone: accountant.phone
        ? sanitizeInput(SanitizeInputType.NUMERIC, accountant.phone)
        : null,
      email: accountant.email ? accountant.email : null,
      zipCode: accountant.zipCode ? accountant.zipCode : null,
      address: accountant.address ? accountant.address : null,
      number: accountant.number ? accountant.number : null,
      district: accountant.district ? accountant.district : null,
      complement: accountant.complement ? accountant.complement : null,
      city: accountant.city ? accountant.city : null,
      state: accountant.state ? accountant.state : null,
    };

    const existAccountantResponse = await api.post("/users/find-email", {
      email: accountant.email,
    });
    const existAccountant = existAccountantResponse.data;

    if (existAccountant && existAccountant.isAccountant !== "y") {
      throw new Error(
        "Email do contador da empresa não corresponde à um usuário contador!"
      );
    }

    const existAccountantCompanies = existAccountant
      ? JSON.parse(existAccountant.branchesIds ?? "[]")
      : [];
    const existingAccountantIsLinkedWithCompany = existAccountantCompanies.includes(
      company.id
    );

    // Contador não existe, criar novo
    if (!existingAccountantIsLinkedWithCompany && !accountant.userId) {
      const accountantSplit = accountant.name.trim().split(" ");
      const firstname = accountantSplit.shift();
      const lastname = accountantSplit.join(" ");

      const createdUser = await UserService.createAccountantUser({
        existAccountant,
        accountantCreatedFromIndication: false,
        accountantNewCompaniesIds: [company.id],
        firstname: firstname?.trim() ?? "",
        lastname: lastname?.trim() ?? "",
        cell: accountant.cell,
        email: accountant.email,
        canAccessMainCompany: yesOrNo.YES,
        photoFile: null,
        currentCompany: company,
        permissionsForCurrentCompany: JSON.parse(company.permissions ?? "[]"),
        fastAccessForCurrentCompany: JSON.parse(company.permissions ?? "[]"),
      });

      accountantRaw.userId = createdUser.id;
    }

    if (accountant.id) {
      // Atualizar contador já criado
      if (accountant.userId || existAccountant) {
        const accountantUserId = accountant.userId
          ? accountant.userId
          : existAccountant.id;

        const updateUserRaw = {
          email: accountant.email,
          cell: accountant.cell,
        };

        accountantRaw.userId = accountantUserId;

        await api.put(`/users/${accountantUserId}`, updateUserRaw);
      }

      await api.put(`/companyAccountants/${accountant.id}`, accountantRaw);
    } else {
      const { data } = await api.post(`/companyAccountants`, accountantRaw);
      accountant.id = data.id;
    }
  }

  getStatusValue(company: any) {
    if (!company) {
      return 0;
    }
    if (company.status !== 0 && company.isSuper === "y") {
      return -1;
    }
    if (!company.status || company.blockMessage) {
      return 0;
    }
    if (company.status) {
      return 1;
    }
    return 0;
  }

  getStatusText(company: any) {
    switch (this.getStatusValue(company)) {
      case -1:
        return "MASTER";
      case 0:
        return "DESATIVADO";
      case 1:
        return "ATIVO";
    }
  }

  companyCanHaveBranches(company: any) {
    return company.plan && company.plan.subscriptionPlan.max_branches !== 0;
  }

  getCorporateName(company: any): string {
    if (!company) return "";
    return company.typePeople === "legal"
      ? company.corporateName
      : company.name;
  }

  getCpfCnpj(company: any): string {
    if (!company) return "";
    return company.typePeople === "legal" ? company.cnpj : company.cpf;
  }

  isBranch(company: any) {
    return !!company?.mainCompanyId;
  }

  isMainCompany(company?: any) {
    return !company?.mainCompanyId;
  }

  private async setSubscriptionPlans(allCompanies: any[]) {
    const mapped = await Promise.all(
      allCompanies.map(async (company, index) => {
        const [companyPlan] = (
          await api.get(`/companies/${company.id}/subscriptionPlans`)
        ).data;
        return {
          ...allCompanies[index],
          plan: companyPlan,
        };
      })
    );

    return mapped;
  }
}

export default new CompanyService();
