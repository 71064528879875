import React, {
  FormEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  makeStyles,
  MenuItem,
  TextField,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import { Button, Tab, Tabs, Spinner, Modal, Alert } from "react-bootstrap";

import { useSubheader } from "../../../_metronic/layout";

import api from "../../services/Api";
import { paymentOptions } from "../../utils/paymentOptions";
import ModalError from "../../components/ModalError";
import { NumericFormat } from "../../components/NumericFormat";
import {
  formatCurrency,
  formatNumberToString,
  formatToFloat,
} from "../../utils/formatCurrency";

import "../../style.css";
import useChangeBillSituation from "../../hooks/changeBillSituation";
import { format } from "date-fns";
import ModalChangeBillSituation from "../../components/ModalChangeBillSituation";
import LogService from "../../services/LogService";
import { getSituationText } from "../../utils/getSituationText";
import { SubCategory } from "../../types/Dre";
import { BillsToPay } from "../../types/BillsToPay";
import SellerService from "../../services/SellerService";
import CustomerService from "../../services/CustomerService";
import { partialPayments } from "../../components/ListWithModalChangeSituation";
import { useSelector } from "react-redux";
import ApiResourceSelect from "../../components/ApiResourceSelect";
import BankAccountService from "../../services/BankAccountService";
import CenterCostService from "../../services/CenterCostService";
import { BankAccount } from "../../types/BankAccount";
import DreCategoryService from "../../services/DreCategoryService";
import { CenterCost } from "../../types/CenterCost";
import { formatStringDateToLocale } from "../../utils/dateFormat";
import {
  dateIsBetweenRange,
  extractDateStringFromTimestamp,
} from "../../utils/dateTimeHelper";
import BillToPayService from "../../services/BillToPayService";
import { ApiResourceSelectAddButton } from "../../components/ApiResourceSelectAddButton";
import { NewCustomerModal } from "../../components/Customer/NewCustomerModal";
import useNewCustomerForm from "../../hooks/newCustomerForm";
import { Customer } from "../../types/Customer";
import { AddAccountBankModal } from "../../components/AccountBank/AddAccountBankModal";
import useAddAccountBank from "../../hooks/addAccountBank";
import { useCompanyBranch } from "../../hooks/companyBranch";
import UploadFiles from "../../components/UploadFiles";
import {
  deleteMultipleFiles,
  uploadMultipleFiles,
} from "../../utils/FilesHelper";

type Beneficiary = {
  id: number;
  name: string;
};

type partialPaymentsView = partialPayments & {
  bankAccountName?: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

export function EditBillsToPay() {
  const { user } = useSelector((state: any) => state.auth);
  const { selectedCompany } = useCompanyBranch();

  const [activeTab, setActiveTab] = useState("detalhes-despesa");

  const [beforeSubmitData, setBeforeSubmitData] = useState<any>({});
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  const [barCode, setBarCode] = useState("");
  const [payment, setPayment] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [isPaid, setIsPaid] = useState(true);
  const [isCanceled, setIsCanceled] = useState(false);
  const [reasonCanceled, setReasonCanceled] = useState("");
  const [comments, setComments] = useState("");
  const [beneficiaryType, setBeneficiaryType] = useState("supplier");
  const [beneficiaryId, setBeneficiaryId] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [centerCost, setCenterCost] = useState("");
  const [occurrence, setOccurrence] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [dreSubCategoryId, setDreSubCategoryId] = useState(0);

  const [isSubmit, setIsSubmit] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [showModalError, setShowModalError] = useState(false);

  // Inputs Verify
  const [expensesError, setExpensesError] = useState(false);
  const [payMethodsError, setPayMethodsError] = useState(false);
  const [dueError, setDueError] = useState(false);
  const [valueError, setValueError] = useState(false);
  const [issueDateError, setIssueDateError] = useState(false);

  const [partialJson, setPartialJson] = useState<partialPaymentsView[]>([]);
  const [pagesPartial, setPagesPartial] = useState(0);
  const [rowsPerPagePartial, setRowsPerPagePartial] = useState(10);
  const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
  const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);

  const classes = useStyles();
  const history = useHistory();
  const subHeader = useSubheader();
  const { id } = useParams<{ id: string }>();
  const {
    isSituationModalOpen,
    setIsSituationModalOpen,
    isReversalModalOpen,
    setIsReversalModalOpen,
    dueDate: dueDateInfo,
    setDueDate: setDueDateInfo,
    remaining,
    setRemaining,
    status,
    setStatus,
    paymentDate,
    setPaymentDate,
    valueToPay,
    setValueToPay,
    fee,
    setFee,
    discount,
    setDiscount,
    tax,
    setTax,
    acc,
    setAcc,
    total,
    setTotal,
    writeOffValue,
    partialPayments,
    setPartialPayments,
    validateDiscount,
    getChangedStatus,
  } = useChangeBillSituation();

  subHeader.setTitle("Adicionar Conta a Pagar");

  // Modal AddAccountBank
  const {
    showModalAddAccountBank,
    setShowModalAddAccountBank,
  } = useAddAccountBank();
  const handleCreateAccountBank = (accountBank: BankAccount) => {
    setBankAccount(String(accountBank.id));
  };

  // Modal New Customer
  const {
    showModalNewCustomer,
    setShowModalNewCustomer,
    newCustomerDefaultData,
    setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setBeneficiaryId(String(createdCustomer.id));
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText, typeRegister: "supplier" });
    setShowModalNewCustomer(true);
  };

  useLayoutEffect(() => {
    async function loadData() {
      const { data } = await api.get<BillsToPay>(`billsToPay/${id}`);
      // const banks    = await api.get<BankAccount>(`/accountBank/${data.bankAccount}`);

      setName(data.name);
      setAmount(data.amount);
      setRemaining(data.remaining);
      // setIsPaid(data.status == "paid" ? true : false);
      setIsCanceled(data.status == "canceled" ? true : false);
      setReasonCanceled(data.reasonCanceled);
      setBarCode(data.barCode ?? "");
      setPayment(data.payment);
      setDueDate(data.dueDate);
      setComments(data.comments ?? "");
      setBeneficiaryType(data.beneficiaryType);
      setBeneficiaryId(
        data.beneficiaryType === "supplier"
          ? String(data.supplier ?? "")
          : String(data.seller ?? "")
      );
      setBeneficiaryName(
        data.beneficiaryType === "supplier"
          ? CustomerService.getCustomerName(data.customerEntity)
          : SellerService.getName(data.sellerEntity)
      );
      setIssueDate(data.issueDate);
      setDocNumber(data.docNumber ?? "");
      setCenterCost(data.centerCost ? String(data.centerCost) : "");
      setOccurrence(data.occurrence);
      setBankAccount(data.bankAccount);
      setCategoryName(data.categoryName);
      setDreSubCategoryId(data.dreSubCategoryId);

      setAcc(formatCurrency(data.accValue ?? 0));
      setTax(formatCurrency(data.taxValue ?? 0));
      setFee(formatCurrency(data.feeValue ?? 0));
      setDiscount(formatCurrency(data.discountValue ?? 0));
      setStatus(data.status);
      setPartialPayments(data.partialPayments);
      setFilesNamesSaved(data.attachments ? JSON.parse(data.attachments) : []);

      if (data.status == "paid") {
        setValueToPay(formatCurrency(data.amount));
      } else {
        setValueToPay(formatCurrency(data.remaining));
      }

      setPaymentDate(BillToPayService.getBillPaymentDate(data) ?? "");

      setBeforeSubmitData(data);
    }

    loadData();
  }, [id]);

  useEffect(() => {
    async function mapPartialPayments() {
      const partials: partialPayments[] = partialPayments
        ? JSON.parse(partialPayments)
        : [];

      const mapped: partialPaymentsView[] = await Promise.all(
        partials.map(async (partial) => {
          const bank = await BankAccountService.getBankAccountById(
            partial.bankAccount
          );
          return {
            ...partial,
            bankAccountName: bank ? `${bank.nameBank} - ${bank.name}` : "",
          };
        })
      );

      setPartialJson(mapped);
    }

    mapPartialPayments();
  }, [partialPayments]);

  useEffect(() => {
    const oldAmount = beforeSubmitData.amount;
    const oldRemaining = beforeSubmitData.remaining;
    let remainingValue = 0;

    if (oldAmount === oldRemaining) {
      remainingValue = amount;
    } else {
      const paidValue = oldAmount - oldRemaining;
      remainingValue = amount - paidValue;
      remainingValue = remainingValue < 0 ? 0 : remainingValue;
    }

    if (remainingValue === 0) {
      setIsPaid(true);
    }

    setRemaining(remainingValue);
  }, [beforeSubmitData, amount]);

  useEffect(() => {
    const valueNumber = formatToFloat(valueToPay);
    const feeNumber = formatToFloat(fee);
    const taxNumber = formatToFloat(tax);
    const accNumber = formatToFloat(acc);
    const discountNumber = formatToFloat(discount);

    validateDiscount({
      valueNumber,
      feeNumber,
      taxNumber,
      accNumber,
      discountNumber,
    });
  }, [valueToPay, fee, tax, acc, discount]);

  const handleChangePagePartial = useCallback((next: number) => {
    setPagesPartial(next);
  }, []);

  const handleRowsPerPagePartial = useCallback((value: number) => {
    setPagesPartial(0);
    setRowsPerPagePartial(value);
  }, []);

  function handleOpenChangeSituationModal() {
    const dueDateFormatted = formatStringDateToLocale(dueDate);
    const defaultPaymentDate = dueDate || format(Date.now(), "yyyy-MM-dd");

    setValueToPay(formatCurrency(remaining));
    setTotal(remaining);
    setDueDateInfo(dueDateFormatted);
    setRemaining(status == "paid" ? 0 : remaining);

    if (status !== "paid") {
      setPaymentDate(defaultPaymentDate);
    }

    setIsSituationModalOpen(true);
  }

  const handleChangeSituation = useCallback(async () => {
    setIsSubmit(true);
    if (!inputsVerify()) return;

    try {
      const changedRemaining = remaining - formatToFloat(valueToPay);
      const changedStatus = getChangedStatus(changedRemaining);

      const partialsJson = partialPayments ? JSON.parse(partialPayments) : null;

      var rawData;
      var auxPartials: any = [];
      if (changedRemaining == 0 && partialsJson == null) {
        rawData = {
          status: changedStatus,
          dueDate,
          payment,
          payedDate: changedStatus === "paid" ? paymentDate : null,
          remaining: changedRemaining,
          bankAccount,
          accValue: Number(formatToFloat(acc)),
          taxValue: Number(formatToFloat(tax)),
          discountValue: Number(formatToFloat(discount)),
          feeValue: Number(formatToFloat(fee)),
          totalPaid: Number(formatToFloat(total)),
        };
      } else if (changedRemaining == 0 && partialsJson) {
        auxPartials = [
          ...partialsJson,
          {
            payedDate: paymentDate,
            writeOffValue,
            feeValue: Number(formatToFloat(fee)),
            discountValue: Number(formatToFloat(discount)),
            taxValue: Number(formatToFloat(tax)),
            accValue: Number(formatToFloat(acc)),
            totalPaid: Number(formatToFloat(total)),
            payment,
            bankAccount,
          },
        ];

        rawData = {
          status: "paid",
          partialPayments: JSON.stringify(auxPartials),
          remaining: changedRemaining,
        };
      } else {
        if (partialsJson) {
          auxPartials = [
            ...partialsJson,
            {
              payedDate: paymentDate,
              writeOffValue,
              feeValue: Number(formatToFloat(fee)),
              discountValue: Number(formatToFloat(discount)),
              taxValue: Number(formatToFloat(tax)),
              accValue: Number(formatToFloat(acc)),
              totalPaid: Number(formatToFloat(total)),
              payment,
              bankAccount,
            },
          ];
        } else {
          auxPartials = [
            {
              payedDate: paymentDate,
              writeOffValue,
              feeValue: Number(formatToFloat(fee)),
              discountValue: Number(formatToFloat(discount)),
              taxValue: Number(formatToFloat(tax)),
              accValue: Number(formatToFloat(acc)),
              totalPaid: Number(formatToFloat(total)),
              payment,
              bankAccount,
            },
          ];
        }

        rawData = {
          partialPayments: JSON.stringify(auxPartials),
          remaining: changedRemaining,
        };
      }

      await saveBillToPay(rawData);

      setIsSituationModalOpen(false);
      setIsPaid(true);
      setStatus(changedStatus);

      if (auxPartials.length) {
        setPartialPayments(JSON.stringify(auxPartials));
        setRemaining(changedRemaining);
      }
    } catch (error) {
      setShowModalError(true);
      setMsgError("Ocorreu um erro ao salvar a conta!");
      console.log(error.message);
    }
    setIsSubmit(false);
  }, [saveBillToPay]);

  const handleReversal = useCallback(async () => {
    setIsSubmit(true);
    // if (!inputsVerify()) return

    const hasPermission = await BillToPayService.checkIfAuthUserHasPermissionToChangeBillSituationOfPastMonth(
      paymentDate,
      selectedCompany
    );
    if (!hasPermission) {
      setMsgError(
        "O mês fiscal anterior foi encerrado e o usuário não tem permissão para administrar fechamento de mês! Em caso de dúvidas contate o administrador do sistema."
      );
      setIsReversalModalOpen(false);
      setShowModalError(true);
      return;
    }

    try {
      await saveBillToPay({
        status: "pending",
        remaining: amount,
        accValue: 0,
        taxValue: 0,
        discountValue: 0,
        feeValue: 0,
        totalPaid: 0,
        payedDate: "",
        partialPayments: null,
      });

      setIsReversalModalOpen(false);
      setIsPaid(true);
      setStatus("pending");
      setAcc("R$0,00");
      setTax("R$0,00");
      setFee("R$0,00");
      setDiscount("R$0,00");
      setRemaining(amount);
      setPartialPayments(null);
    } catch (error) {
      setShowModalError(true);
      setMsgError("Ocorreu um erro ao salvar a conta!");
      console.log(error.message);
    }
    setIsSubmit(false);
  }, [saveBillToPay]);

  function handleCloseBillSituationModal() {
    setIsSituationModalOpen(false);
    setBankAccount("");
  }

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    setIsSubmit(true);

    if (!inputsVerify()) return;

    try {
      await saveBillToPay();

      setIsSubmit(false);

      history.push("/contas-a-pagar");
    } catch (error) {
      setIsSubmit(false);
      console.log(error.message);
    }
  };

  async function saveBillToPay(additionalData?: any) {
    const bank = await api.get(`/accountBank/${parseInt(bankAccount)}`);

    const responseData = await api.get<BillsToPay>(`billsToPay/${id}`);

    const filesRemained = await deleteMultipleFiles(
      responseData.data.attachments
        ? JSON.parse(responseData.data.attachments)
        : [],
      filesNamesSaved,
      setShowModalError,
      setMsgError,
      setIsSubmit
    );
    let filesPath: any = [];
    if (filesSelected) {
      filesPath = await uploadMultipleFiles(
        filesSelected,
        setShowModalError,
        setMsgError,
        setIsSubmit
      );
      if (filesPath === false) {
        return;
      }
    } else {
      filesPath = [];
    }

    let data: any = {
      isPaid,
      name,
      amount,
      remaining: Number(remaining),
      bankAccount: bankAccount ? parseInt(bankAccount) : null,
      nameBank: bankAccount ? bank.data.nameBank : null,
      barCode: !!barCode && barCode.length > 0 ? barCode : null,
      categoryName:
        !!categoryName && categoryName.length > 0 ? categoryName : null,
      centerCost:
        !!centerCost && centerCost.length > 0 ? parseInt(centerCost) : null,
      comments: !!comments && comments.length > 0 ? comments : null,
      docNumber: !!docNumber && docNumber.length > 0 ? docNumber : null,
      dueDate: !!dueDate && dueDate.length > 0 ? dueDate : null,
      issueDate: !!issueDate && issueDate.length > 0 ? issueDate : null,
      // occurrence: !!occurrence && occurrence.length > 0 ? occurrence : null,
      payment: !!payment && payment.length > 0 ? payment : null,
      beneficiaryType,
      supplier: beneficiaryType === "supplier" ? beneficiaryId || null : null,
      seller: beneficiaryType === "seller" ? beneficiaryId || null : null,
      dreSubCategoryId:
        !!dreSubCategoryId && dreSubCategoryId > 0 ? dreSubCategoryId : null,
      attachments: JSON.stringify([...filesRemained, ...filesPath]),
    };

    if (remaining === 0) {
      data.status = "paid";
    }

    if (additionalData) {
      data = {
        ...data,
        ...additionalData,
      };
    }

    const response = await api.put(`billsToPay/${id}`, data);

    LogService.logEdit({
      itemId: response.data.id,
      itemName: response.data.name,
      module: "Contas a Pagar",
      oldData: {
        ...beforeSubmitData,
        beneficiaryName:
          beforeSubmitData.beneficiaryType === "supplier"
            ? CustomerService.getCustomerName(beforeSubmitData.customerEntity)
            : SellerService.getName(beforeSubmitData.sellerEntity),
      },
      newData: {
        ...response.data,
        beneficiaryName,
      },
      formattedFields: {
        bankAccount: async (value) =>
          (await BankAccountService.getBankAccountById(Number(value)))?.name ??
          "",
        centerCost: async (value) =>
          (await CenterCostService.getCenterCostById(Number(value)))?.name ??
          "",
        issueDate: (value) =>
          value ? new Date(value).toLocaleDateString() : "",
        dueDate: (value) => (value ? new Date(value).toLocaleDateString() : ""),
        amount: (value) => formatNumberToString(value),
        status: (value) => getSituationText(value),
      },
      fieldsMap: {
        name: "Nome da despesa",
        bankAccount: "Conta bancária",
        payment: "Forma de pagamento",
        categoryName: "Plano de contas",
        beneficiaryName: "Beneficiário",
        dueDate: "Data de Vencimento",
        amount: "Valor",
        issueDate: "Data da emissão",
        barCode: "Código de barras",
        docNumber: "Nº do documento",
        centerCost: "Centro de custos",
        comments: "Observações",
        status: "Status",
      },
    });

    setBeforeSubmitData(response.data);
  }

  function inputsVerify() {
    setExpensesError(false);
    setPayMethodsError(false);
    setDueError(false);
    setValueError(false);
    setIssueDateError(false);

    if (!name) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Nome da despesa é obrigatório!");
      setShowModalError(true);
      setExpensesError(true);
      return false;
    }

    if (!bankAccount) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Selecione uma conta bancária!");
      setShowModalError(true);
      return false;
    }

    // if(!categoryName) {
    //     setIsSubmit(false);
    //     setMsgError("Campo Plano de Contas é obrigatório!");
    //     setShowModalError(true);
    //     return false;
    // }

    if (!payment) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Forma de Pagamento é obrigatório!");
      setShowModalError(true);
      setPayMethodsError(true);
      return false;
    }

    if (!dueDate) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Vencimento é obrigatório!");
      setShowModalError(true);
      setDueError(true);
      return false;
    }

    if (!amount) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Valor é obrigatório!");
      setShowModalError(true);
      setValueError(true);
      return false;
    }
    if (amount <= 0) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Valor deve ser maior que zero!");
      setShowModalError(true);
      setValueError(true);
      return false;
    }

    if (!issueDate) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Data de Emissão é obrigatório!");
      setShowModalError(true);
      setIssueDateError(true);
      return false;
    }

    if (issueDate) {
      const dateIsValid = dateIsBetweenRange(
        issueDate,
        "",
        extractDateStringFromTimestamp()
      );
      if (!dateIsValid) {
        setActiveTab("detalhes-despesa");
        setIsSubmit(false);
        setMsgError("A data de emissão não pode ser maior que a data atual!");
        setShowModalError(true);
        setIssueDateError(true);
        return false;
      }
    }

    return true;
  }

  function handleChangeBeneficiaryType(value: string) {
    setBeneficiaryType(value);
    setBeneficiaryId("");
    setBeneficiaryName("");
  }

  return (
    <div className="row card card-body pt-4 newProductWrapper">
      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />

      {/* Modal de situação */}
      <ModalChangeBillSituation
        isSituationModalOpen={isSituationModalOpen}
        handleCloseModal={handleCloseBillSituationModal}
        viewOnly={status == "paid"}
        situationModalTitle={
          status == "paid" ? "Ver pagamento" : "Liquidar conta a pagar"
        }
        billsToPay={true}
        name={name}
        status={status}
        supplier={beneficiaryName}
        dueDate={dueDateInfo}
        amount={amount}
        remaining={remaining}
        paymentDate={paymentDate}
        setPaymentDate={setPaymentDate}
        payment={payment}
        setPayment={setPayment}
        bankAccount={bankAccount}
        handleChangeBankAccount={(bankAccount) =>
          setBankAccount(String(bankAccount?.id ?? ""))
        }
        valueToPay={valueToPay}
        setValueToPay={setValueToPay}
        fee={fee}
        setFee={setFee}
        discount={discount}
        setDiscount={setDiscount}
        tax={tax}
        setTax={setTax}
        acc={acc}
        setAcc={setAcc}
        handleChangeSituation={handleChangeSituation}
        total={total}
        setTotal={setTotal}
        writeOffValue={writeOffValue}
        partialPayments={partialPayments}
        isPaid={isPaid}
        setIsPaid={setIsPaid}
        isSubmitting={isSubmit}
      />

      <AddAccountBankModal
        showModal={showModalAddAccountBank}
        setShowModal={setShowModalAddAccountBank}
        onCreateAccountBank={handleCreateAccountBank}
      />

      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
      />

      {/* Modal estorno */}
      <Modal
        centered
        aria-labelledby="contained-modal-warning"
        show={isReversalModalOpen}
        onHide={() => setIsReversalModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <strong>Tem certeza que deseja estornar este registro ?</strong>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="warning"
            onClick={() => handleReversal()}
            disabled={isSubmit}
          >
            {isSubmit ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <>
                <span>Sim</span>
              </>
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsReversalModalOpen(false)}
          >
            Não
          </Button>
        </Modal.Footer>
      </Modal>

      <form
        className={"makeStyles-container-12"}
        onSubmit={(evt) => onSubmit(evt)}
      >
        <Tabs
          activeKey={activeTab}
          onSelect={(tab: string) => setActiveTab(tab)}
          id="newproduct-form-tabs"
        >
          <Tab
            eventKey="detalhes-despesa"
            title="Detalhes da Despesa"
            className="mb-15"
          >
            <div className="row">
              <div className="col-lg-3">
                <TextField
                  label="Nome da despesa*"
                  margin="normal"
                  error={expensesError}
                  className={classes.error}
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={(evt) => {
                    setName(evt.target.value);
                    setExpensesError(evt.target.value ? false : true);
                  }}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                />
              </div>

              <div className="col-lg-3">
                <ApiResourceSelect
                  label="Conta bancária *"
                  getOptionLabel={(option: BankAccount) =>
                    `${option.nameBank} - ${option.name}`
                  }
                  value={bankAccount}
                  hasError={showModalError}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                  onSelect={(option) =>
                    setBankAccount(String(option?.id ?? ""))
                  }
                  apiSearchHandler={(typedText) =>
                    BankAccountService.getBankAccountsFiltered({
                      name: typedText,
                      situation: "y",
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!bankAccount) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(bankAccount)
                      ) ?? BankAccountService.getBankAccountById(bankAccount)
                    );
                  }}
                  renderAddButton={
                    <ApiResourceSelectAddButton
                      label="Adicionar Conta Bancária"
                      onClick={() => setShowModalAddAccountBank(true)}
                    />
                  }
                />
              </div>

              <div className="col-lg-3">
                <TextField
                  select
                  label="Forma de Pagamento*"
                  className={`${classes.textField} ${classes.error}`}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={payment}
                  onChange={(evt) => {
                    setPayment(evt.target.value);
                    setPayMethodsError(evt.target.value ? false : true);
                  }}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                  error={payMethodsError}
                >
                  <MenuItem key="0" value="">
                    Selecione
                  </MenuItem>

                  {paymentOptions.map((payment, index) => (
                    <MenuItem key={index + 1} value={payment.value}>
                      {payment.value}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div className="col-lg-3">
                <ApiResourceSelect
                  label="Plano de Contas *"
                  getOptionLabel={(option: SubCategory) => option.name}
                  value={dreSubCategoryId}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                  onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                  apiSearchHandler={(typedText) =>
                    DreCategoryService.getDreSubCategoriesFiltered(
                      { name: typedText },
                      "expense"
                    )
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!dreSubCategoryId) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(dreSubCategoryId)
                      ) ??
                      DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                    );
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-2">
                <TextField
                  value={beneficiaryType}
                  select
                  label="Tipo Beneficiário"
                  size="small"
                  className="ml-0"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => handleChangeBeneficiaryType(e.target.value)}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                >
                  <MenuItem key="0" value="supplier">
                    Fornecedor
                  </MenuItem>

                  <MenuItem key="1" value="seller">
                    Vendedor
                  </MenuItem>
                </TextField>
              </div>

              <div className="col-lg-4 d-flex align-items-center">
                <ApiResourceSelect
                  style={{ width: "100%" }}
                  label="Beneficiário"
                  getOptionLabel={(option: Beneficiary) =>
                    `${option.id} - ${option.name}`
                  }
                  value={beneficiaryId}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                  onSelect={(option) => {
                    setBeneficiaryId(option ? String(option.id) : "");
                    setBeneficiaryName(option?.name ?? "");
                  }}
                  apiSearchHandler={
                    beneficiaryType === "supplier"
                      ? (typedText) =>
                          CustomerService.getCustomersFiltered({
                            name: typedText,
                            nameWithId: true,
                          })
                      : (typedText) =>
                          SellerService.getSellersFiltered({ name: typedText })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!beneficiaryId) return null;

                    const loadedBeneficiary = loadedOptions.find(
                      (option) => option.id === Number(beneficiaryId)
                    );
                    if (loadedBeneficiary) return loadedBeneficiary;

                    return beneficiaryType === "supplier"
                      ? CustomerService.getCustomerById(beneficiaryId)
                      : SellerService.getSellerById(beneficiaryId);
                  }}
                  renderAddButton={
                    beneficiaryType === "supplier"
                      ? (typedText) => (
                          <ApiResourceSelectAddButton
                            label="Adicionar Fornecedor"
                            onClick={() => handleClickAddCustomer(typedText)}
                          />
                        )
                      : undefined
                  }
                />
              </div>

              <div className="col-lg-2">
                <TextField
                  type="date"
                  label="Vencimento*"
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dueDate}
                  onChange={(evt) => {
                    setDueDate(evt.target.value);
                    setDueError(evt.target.value ? false : true);
                  }}
                  size="small"
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                  error={dueError}
                />
              </div>

              <div className="col-lg-2">
                <NumericFormat
                  label="Valor*"
                  startAdornment="R$"
                  error={valueError}
                  className={classes.error}
                  value={amount}
                  onChange={(evt) => {
                    setAmount(formatToFloat(evt.target.value));
                    setValueError(evt.target.value ? false : true);
                  }}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                />
              </div>

              <div className="col-lg-2">
                <TextField
                  type="date"
                  label="Data de emissão *"
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={issueDate}
                  onChange={(evt) => setIssueDate(evt.target.value)}
                  size="small"
                  error={issueDateError}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                />
              </div>

              {/* <div className="col-lg-2 d-flex align-items-center">
                                <FormControlLabel
                                    label="Pago"
                                    className="ml-1"
                                    checked={isPaid}
                                    onChange={() => setIsPaid(state => !state)}
                                    control={
                                        <Checkbox color="primary" />
                                    }
                                />
                            </div> */}
            </div>

            <div className="row">
              <div className="col-lg-5">
                <TextField
                  label="Código de barras"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={barCode}
                  onChange={(evt) => setBarCode(evt.target.value)}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </Tab>

          <Tab
            eventKey="detalhes-conta"
            title="Detalhes da Conta"
            className="mb-15"
          >
            <div className="row">
              <div className="col-lg-6">
                <TextField
                  label="Nº do documento"
                  className="ml-0"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={docNumber}
                  onChange={(evt) => setDocNumber(evt.target.value)}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                />
              </div>

              <div className="col-lg-3">
                <ApiResourceSelect
                  label="Centro de Custos"
                  getOptionLabel={(option: CenterCost) => option.name}
                  value={centerCost}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                  onSelect={(option) =>
                    setCenterCost(option ? String(option.id) : "")
                  }
                  apiSearchHandler={(typedText) =>
                    CenterCostService.getCenterCostsFiltered({
                      name: typedText,
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!centerCost) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(centerCost)
                      ) ?? CenterCostService.getCenterCostById(centerCost)
                    );
                  }}
                />
              </div>
            </div>

            {/* <div className="row">
                            <div className="col-lg-3">
                                <TextField
                                    select
                                    label="Ocorrência"
                                    className="ml-0"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={occurrence}
                                    onChange={(evt) => setOccurrence(evt.target.value)}
                                >
                                    <MenuItem key="0" value="unica">
                                        Única
                                    </MenuItem>

                                    <MenuItem key="1" value="parcelada">
                                        Parcelada
                                    </MenuItem>
                                </TextField>
                            </div>
                        </div> */}

            <div className="row">
              <div className="col-lg-5">
                <UploadFiles
                  label="Anexos"
                  filesSelected={filesSelected}
                  setFilesSelected={setFilesSelected}
                  filesNamesSaved={filesNamesSaved}
                  setFilesNamesSaved={setFilesNamesSaved}
                  disabled={
                    status == "paid" || isCanceled || user.isAccountant == "y"
                      ? true
                      : false
                  }
                />
              </div>
            </div>

            <TextField
              label="Observações"
              multiline
              rows="2"
              className="ml-0"
              margin="normal"
              variant="outlined"
              size="small"
              value={comments}
              onChange={(evt) => setComments(evt.target.value)}
              disabled={
                status == "paid" || isCanceled || user.isAccountant == "y"
                  ? true
                  : false
              }
            />
          </Tab>

          {partialPayments ? (
            <Tab
              eventKey="detalhes-pagamento"
              title="Detalhes do Pagamento"
              className="mb-15"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Valor baixa</TableCell>
                    <TableCell>Juros</TableCell>
                    <TableCell>Desconto</TableCell>
                    <TableCell>Taxa</TableCell>
                    <TableCell>Acréscimo</TableCell>
                    <TableCell>Valor pago</TableCell>
                    <TableCell>Conta bancária</TableCell>
                    <TableCell>Forma pag.</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {partialJson
                    .slice(
                      pagesPartial * rowsPerPagePartial,
                      pagesPartial * rowsPerPagePartial + rowsPerPagePartial
                    )
                    .map((value, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {value.payedDate
                              .split("-")
                              .reverse()
                              .join("/")}
                          </TableCell>
                          <TableCell>
                            {formatCurrency(value.writeOffValue)}
                          </TableCell>
                          <TableCell>
                            {formatCurrency(value.feeValue)}
                          </TableCell>
                          <TableCell>
                            {formatCurrency(value.discountValue)}
                          </TableCell>
                          <TableCell>
                            {formatCurrency(value.taxValue)}
                          </TableCell>
                          <TableCell>
                            {formatCurrency(value.accValue)}
                          </TableCell>
                          <TableCell>
                            {formatCurrency(value.totalPaid)}
                          </TableCell>
                          <TableCell>{value.bankAccountName ?? ""}</TableCell>
                          <TableCell>{value.payment}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>

              <TablePagination
                labelRowsPerPage="Linhas por página"
                page={pagesPartial}
                component="div"
                count={partialJson.length}
                rowsPerPage={rowsPerPagePartial}
                rowsPerPageOptions={[5, 10, 25]}
                backIconButtonProps={{
                  "aria-label": "Página Anterior",
                }}
                nextIconButtonProps={{
                  "aria-label": "Próxima Página",
                }}
                onChangePage={(_, next) => handleChangePagePartial(next)}
                onChangeRowsPerPage={(evt) =>
                  handleRowsPerPagePartial(Number(evt.target.value))
                }
              />
            </Tab>
          ) : (
            <></>
          )}
        </Tabs>

        {isCanceled ? (
          <Alert variant="secondary" className="col-lg-6">
            <Alert.Heading>Motivo do cancelamento</Alert.Heading>
            <p>{reasonCanceled}</p>
          </Alert>
        ) : (
          <></>
        )}

        <div className="col-lg-6 d-flex flex-row">
          {user.isAccountant == "n" ? (
            <>
              {status == "paid" ? (
                <>
                  <Button
                    type="button"
                    size="lg"
                    className="mr-3"
                    variant="secondary"
                    onClick={() => setIsReversalModalOpen(true)}
                  >
                    <span>Estornar Conta</span>
                  </Button>
                </>
              ) : (
                <>
                  {!isCanceled ? (
                    <Button
                      type="submit"
                      className="mr-3"
                      variant="primary"
                      disabled={isSubmit}
                    >
                      {isSubmit ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />

                          <span className="ml-2">Aguarde...</span>
                        </>
                      ) : (
                        <>
                          <span>Salvar</span>
                        </>
                      )}
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          ) : (
            <></>
          )}

          {!isCanceled ? (
            <Button
              type="button"
              size="lg"
              className="mr-3"
              variant="secondary"
              onClick={handleOpenChangeSituationModal}
            >
              <span>
                {status == "paid" ? "Ver pagamento" : "Liquidar conta"}
              </span>
            </Button>
          ) : (
            <></>
          )}

          <Link href="/contas-a-pagar" className="btn btn-secondary">
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
}
